// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cx from 'classnames';
import { Helmet } from "react-helmet";

import styles from "./assets/privacyStyles.module.css"

function privacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Your Privacy Matters: Our Privacy Policy | Jasthink Softwares</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Learn how Jasthink Softwares protects your privacy and handles your personal information. Our Privacy Policy outlines our commitment to safeguarding your data and ensuring transparency in our practices." />        
                <meta name="keywords" content="Privacy policy, data protection, personal information, privacy statement, data privacy, privacy practices, privacy compliance, GDPR compliance, privacy regulations, Jasthink Softwares privacy policy"></meta>
            </Helmet>  
            <section className={styles.heroBanner}>
                <Container>
                    <h4><strong>Privacy Policy</strong></h4>
                </Container>
            </section>
            <Container className={styles.policyContainer}>
                <Row>
                    <Col md={9}>                        
                        <h5><strong>Introduction</strong></h5>
                        <p>Welcome to the privacy policy of Jasthink Softwares Pvt. Ltd ("we," "us," or "our"). This policy outlines how we
                            collect, use, and protect your personal information when you use our website.</p>
                        <h5><strong>Information We Collect</strong></h5>
                        <p>We may collect personal information such as your name, email address, and other details when you interact
                            with our website or services.</p>
                        <h5><strong>How We Use Your Information</strong></h5>
                        <p>We use the collected information for purposes such as providing services, improving our website, and
                            communicating with you. We do not share your information with third parties except as required by law.</p>
                        <h5><strong>Security</strong></h5>
                        <p>We take reasonable measures to protect your personal information. However, no online method is 100%
                            secure. Please take appropriate precautions when sharing information online.</p>
                        <h5><strong>Third-Party Links</strong></h5>
                        <p>Our website may contain links to third-party websites. We are not responsible for their privacy practices.
                            Please review the privacy policies of these websites.</p>
                        <h5><strong>Changes to This Policy</strong></h5>
                        <p>We may update our privacy policy. Please review this page periodically for any changes.</p>
                        <h5><strong>Contact Us</strong></h5>
                        <p>If you have questions about our privacy policy, please contact us at <a
                            href="mailto:support@jasthinksoftwares.com">support@jasthinksoftwares.com</a>.</p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default privacyPolicy;