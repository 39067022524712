import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Styles from "./assets/styles.css";

const ourServices = () => {
    useEffect(() => {
        // document.title = `You clicked ${count} times`;
    });

    let serviceData = [
        {
            title: "Cloud<br />Computing",
            content: "Ensure scalability, security, and flexibility with our cloud solutions.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/cloud-network.gif",
            tileLnk: "/services/cloud-computing"
        },
        {
            title: "Technology<br />Consultancy",
            content: "Let's explore strategic insights to create road map for successful business goals.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/artificial-intelligence.gif",
            tileLnk: "/services/consultancy"
        },
        {
            title: "Enterprise<br />Solutions",
            content: "Transform your business operations with our comprehensive enterprise solutions.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/analytics.gif",
            tileLnk: "/services/enterprise-solution"
        },
        {
            title: "Customer<br />Experience Design",
            content: "We are dedicated to creating intuitive, visually appealing interfaces that engage your customers.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/responsive-design.gif",
            tileLnk: "/services/ux-cx-design"
        },
        {
            title: "Mobile App<br />Development",
            content: "Mobiles with seamless experiences that  that leave a lasting impression and engaging user satisfaction.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/payment-app.gif",
            tileLnk: "/services/mobile-app-development"
        },
        {
            title: "Web App<br />Development",
            content: "Collaboratively we build awesome wesites/applications that are fast, interactive and intutive.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/applications.gif",
            tileLnk: "/services/web-app-development"
        },
    ]

    const ServiceTile = serviceData.map((tile, i) =>
        // <Col md={6}>
        //     <Card className={"scrollTile"}>
        //         <Card.Body>
        //             <img className={"tileImage"} src={tile.bgImage} />
        //             <Card.Title dangerouslySetInnerHTML={{ __html: tile.title }}></Card.Title>
        //             <Card.Text>
        //                 <p>{tile.content}</p>
        //             </Card.Text>
        //             <Card.Footer>
        //                 <a className={"service-lnk"} href={tile.tileLnk}>Read More &gt;</a>
        //             </Card.Footer>
        //         </Card.Body>                
        //     </Card>
        // </Col>

        <Col lg={4} md={6} className="feature service-feature text-center">
            <a className={"service-lnk icon-link"} href={tile.tileLnk}>
                <span className="feature-icon">
                    <img className={"tileImage"} src={tile.bgImage} />
                </span>
                <span className={"feature-title"} dangerouslySetInnerHTML={{ __html: tile.title }}></span>
                <span className={"feature-content"}>{tile.content}</span>
                <span className={"feature-chevright"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                    </svg>                    
                </span>
            </a>
        </Col>

    );
    return (
        <section className={"our-services-section services-section"}>
            <Container>
                <Row>
                    <Col lg={8} className={"text-center mx-auto"}>
                        <h5 className="sub-head mt-4">EMPOWERING YOUR DIGITAL JOURNEY</h5>
                        <h2 className={'main-head'}>Tailored IT Solutions Crafted with Expertise and Innovation</h2>
                        <p>Discover our IT services designed to adapt quickly, masterfully crafted to suit your needs. We specialize in web and mobile development, ensuring your projects excel with expert care and innovation.</p>
                    </Col>
                </Row>
                <Row className={"g-4 py-5 row-cols-1 row-cols-lg-3"}>
                    {ServiceTile}
                </Row>
            </Container>
        </section>
    );
}
export default ourServices;