import React, { Component } from 'react';
import './styles.css'

class Typewriter extends Component {
    constructor(props) {
        super(props);
        this.state = {
          toRotate: JSON.parse(props.toRotate),
          el: null,
          loopNum: 0,
          period: parseInt(props.period, 10) || 2000,
          txt: '',
          isDeleting: false,
          textColor: props.textColor || 'black', // Default text color is black
        };
      }

  componentDidMount() {
    this.tick();
  }

  tick = () => {
    const { loopNum, toRotate, isDeleting, txt } = this.state;
    const i = loopNum % toRotate.length;
    const fullTxt = toRotate[i];

    if (isDeleting) {
      this.setState({ txt: fullTxt.substring(0, txt.length - 1) });
    } else {
      this.setState({ txt: fullTxt.substring(0, txt.length + 1) });
    }

    const { el } = this.state;
    if (el) {
      el.innerHTML = `<span class="wrap" style="color: ${this.state.textColor}">${this.state.txt}</span><span class="blink">|</span>`;
    }

    let delta = 200 - Math.random() * 100;

    if (isDeleting) {
      delta /= 2;
    }

    if (!isDeleting && txt === fullTxt) {
      delta = this.state.period;
      this.setState({ isDeleting: true });
    } else if (isDeleting && txt === '') {
      this.setState({
        isDeleting: false,
        loopNum: loopNum + 1,
        delta: 500,
      });
    }

    setTimeout(this.tick, delta);
  };

  render() {
    return <span className="typewrite" ref={(el) => (this.state.el = el)}></span>;
  }
}

export default Typewriter;
