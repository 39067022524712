// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useInView } from 'react-intersection-observer';

// images

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function ourServices() {
    const [ref, inView] = useInView({
        triggerOnce: false, // Ensures the class is added only once when entering the viewport
    });

    window.onscroll = function(){
        console.log( inView );
    };

    let services = [
        {
            title: "UX Design",
            subTitle: "User focused Visual Interface Design"
        },
        {
            title: "App Services",
            subTitle: "Developing clean and easy to use code"
        },
        {
            title: "Brand Services",
            subTitle: "From creating the brand to positioning"
        },
        {
            title: "DevOps Services",
            subTitle: "Enhancing infrastructure for quality"
        },

    ];

    
    return (
        <>
            <section className={"main-section services-home-section"}>
                <Container>
                    <Row className={"justify-content-between"}>                                               
                        <Col lg={6} className={"align-self-center text-center"}>
                            <svg width="500" height="550" viewBox="0 0 500 550"  ref={ref} className={cx(inView ? 'show' : '', "services-circles")}>
                                <defs>
                                    <clipPath id="clip-plan-market">
                                        <rect width="500" height="550" />
                                    </clipPath>
                                </defs>
                                <g id="plan-market" clipPath="url(#clip-plan-market)">
                                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="248" cy="248" r="248" transform="translate(0 32)" fill="#fceaff" />
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="200.5" cy="200.5" r="200.5" transform="translate(48 116)" fill="#f8ee9c" />
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="155.5" cy="155.5" r="155.5" transform="translate(93 199)" fill="#d4efbb" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="110" cy="110" r="110" transform="translate(138 279)" fill="#faffe3" />
                                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="31.5" cy="31.5" r="31.5" transform="translate(270 9)" fill="#9c1ecb" />
                                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="31.5" cy="31.5" r="31.5" transform="translate(270 180)" fill="#9c1ecb" />
                                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="31.5" cy="31.5" r="31.5" transform="translate(163 269)" fill="#9c1ecb" />
                                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="31.5" cy="31.5" r="31.5" transform="translate(163 96)" fill="#9c1ecb" />
                                    <text id="PLAN" transform="translate(302 47)" fill="#fff" fontSize="16" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700" letterSpacing="-0.04em"><tspan x="-20.528" y="0">PLAN</tspan></text>
                                    <text id="Asses_possible_solutions" data-name="Asses possible solutions" transform="translate(235 74)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-41.1" y="0">Asses possible </tspan><tspan x="-25.236" y="15">solutions</tspan></text>
                                    <text id="Plan_the_course_of_action" data-name="Plan the course of action" transform="translate(361 107)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-43.506" y="0">Plan the course</tspan><tspan x="-24.288" y="15">of action</tspan></text>
                                    <text id="Identify_the_problem" data-name="Identify the problem" transform="translate(125 107)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-32.028" y="0">Identify the</tspan><tspan x="-24.39" y="15">problem</tspan></text>
                                    <text id="Execute_the_plan" data-name="Execute the plan" transform="translate(258 158)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-22.56" y="0">Execute</tspan><tspan x="-23.31" y="15">the plan</tspan></text>
                                    <text id="Monitor_the_progress" data-name="Monitor the progress" transform="translate(218 237)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-22.218" y="0">Monitor</tspan><tspan x="-35.16" y="15">the progress</tspan></text>
                                    <text id="Look_for_loop-holes" data-name="Look for loop-holes" transform="translate(326 266)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-22.656" y="0">Look for</tspan><tspan x="-29.406" y="15">loop-holes</tspan></text>
                                    <text id="Rectify_the_errors" data-name="Rectify the errors" transform="translate(251 356)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-19.332" y="0">Rectify</tspan><tspan x="-26.934" y="15">the errors</tspan></text>
                                    <text id="Standardize_the_process" data-name="Standardize the process" transform="translate(251 414)" fill="#3a3a3a" fontSize="12" fontFamily="Montserrat-Regular, Montserrat" letterSpacing="-0.04em"><tspan x="-33.594" y="0">Standardize</tspan><tspan x="-32.28" y="15">the process</tspan></text>
                                    <text id="CHECK" transform="translate(302 218)" fill="#fff" fontSize="16" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700" letterSpacing="-0.04em"><tspan x="-27.464" y="0">CHECK</tspan></text>
                                    <text id="ACT" transform="translate(195 307)" fill="#fff" fontSize="16" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700" letterSpacing="-0.04em"><tspan x="-15.656" y="0">ACT</tspan></text>
                                    <text id="DO" transform="translate(195 134)" fill="#fff" fontSize="16" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontWeight="700" letterSpacing="-0.04em"><tspan x="-11.832" y="0">DO</tspan></text>
                                </g>
                            </svg>
                        </Col> 
                        <Col lg={6}>
                            <Row>
                                <Col lg={12} md={4} sm={12}>
                                    <h5 className={"sub-head mt-4"}>INNOVATE FOR SUCCESS</h5>
                                    <h2 className={"main-head"}>Technology to marketing, we drive business growth.</h2>
                                </Col>
                                <Col lg={12} md={8} sm={12}>                                    
                                    <h4><strong>Steer your company or product towards success with expert guidance.</strong></h4><br />
                                    <p>Our flexible solutions optimize operations at a reasonable expense, securing your success in the digital world. In the era of an evolving business environment, we nurture our skills to enable our team to combine innovative solutions with data-driven insights.</p>
                                    <p>We strategize to cover all aspects, enhancing efficiency and brand strength for sustained growth and competitive advantage.</p>                                                         
                                    <div className={"hash-tags"}>
                                        #BusinessStrategy #BrandStrength  #StrategicPlanning #DigitalTransformation
                                    </div>
                                    <p><a href="/services" className={"btn btn-primary outlined-btn mt-5 mb-0"}>Learn More</a></p>
                                </Col>
                            </Row>                            
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default ourServices;