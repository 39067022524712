// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//components
import UnderConstruction from '../../components/underConstruction';

// images 

// Styles and Css
import styles from './assets/css/styles.css';
import cx from 'classnames';

function Products() {
    const [count, setCount] = useState(0);
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        document.title = `You clicked ${count} times`;
    });
    
    return (
        <>
           <UnderConstruction />
        </>
    );
}

export default Products;