// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images 
import MobileApp from "./assets/images/mobile-app.svg"

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

//importcomponents

function buildForAll() {
        
    return (
        <>
            <section className={"main-section build-section"}>
                <Container>
                    <Row className={"justify-content-between"}>
                        <Col lg={6} md={4}></Col>
                        <Col lg={6} md={8} className={"sticky-panel sticky-cyan"}>
                            <h5 className={"sub-head"}>THE POWER OF TECHNOLOGY</h5>
                            <h2 className={"main-head"}>Empowerment through Digital Innovations</h2>
                            <p>At the heart of our mission is the dedication.</p>
                            <p>Dedication to uplift communities, bringing together a tapestry of diverse talents, and fostering growth through innovative products and marketplaces.</p>
                            <p>Dedication to craft a brighter and more prosperous future for everyone.</p>
                            <p>Dedication to elevate the living standards with the power of Technology!.</p>
                            {/* <p>At Jasthink we create opportunities to expand economic prospects for both customers, individuals and enterprises, thereby elevating living standards.</p> */}
                            {/* <p>Artificial Intelligence or AI serves as a catalyst for community development. Our dedication lies in leveraging the AI capabilities through educational initiatives, collaborative endeavors, and technologically-driven solutions, a more promising future collectively.</p> */}
                            {/* <p><a href="/services" class="btn btn-primary outlined-btn">Learn More</a></p>  */}
                            <div className={"hash-tags"}>
                                #CommunityUpliftment #InnovationForGrowth #TechEmpowerment
                            </div>
                        </Col> 
                        {/* <Col md={6} className={"justify-content-end floor-shadow"}>
                             <img className={"show-app"} src={ MobileApp } alt="Build for All" />
                        </Col>                        */}
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default buildForAll;