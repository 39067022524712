import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import cx from 'classnames';

import styles from './assets/processStyles.css';

function ProcessSteps(props) {
    const { tileData } = props;

    return(
        <div className={"tile-container"}>
            <div className={"process-tile d-flex"}>
                {tileData.map((tile, index) => (
                    <Card key={index} className={"animate"}>
                        <Card.Body>                
                            <Card.Title>
                                <span>{tile.number}</span> {tile.heading}
                            </Card.Title>
                            <Card.Text>
                                {tile.paragraph}
                            </Card.Text>
                        </Card.Body> 
                    </Card>
                ))}             
            </div>
        </div>       
    );
}

export default ProcessSteps;