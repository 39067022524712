// Components and plugins
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

// components


// images 
import FooterLogo from './assets/images/logo-footer.svg'

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function Footer() {
    return (
        <>
            <Container className={"footerContrainer"} fluid>
                <Row>
                    <div className={'left-background'} >
                        <Container>
                            <Row>
                                <Col md={4} >
                                    <div className={"left-section"}>
                                        <img src={FooterLogo} alt="Jasthink Softwares" className={cx('align-middle', 'footer-logo' )} />
                                        <h5>We build apps. We help brands.</h5>
                                        <p>Strategic planning, creative vision and foresightedness are the skills to build and sustain a successful consumer-centric application. We help startups and enterprises to execute their vision.</p>
                                        <span className="divider"></span>
                                        <address>                                            
                                            <h6>Jasthink Softwares Pvt. Ltd.</h6>
                                            Plot. #57, Ground Floor,
                                            Inustrial Area, Phase I,<br />
                                            Chandigarh UT - 160002
                                        </address>
                                        <ul className={"contact-links"}>
                                            {/* <li><a href="tel:+919023049911">+91 (902) 304 9911</a></li>
                                                <li><a href="tel:+61861020073">+61 8 8121 7881</a></li> */}
                                            <li><a href="mailto:hello@jasthink.com">hello@jasthink.com</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={8} >
                                    <div className={"right-section"}>
                                        <Row>
                                            <Col md={4}>
                                                <Nav defaultActiveKey="/home" className="flex-column">
                                                    <h5>Industries</h5>
                                                    <Nav.Link eventKey="link-1">FinTech</Nav.Link>
                                                    <Nav.Link eventKey="link-1">InsureTech</Nav.Link>
                                                    <Nav.Link eventKey="link-2">Real Estate</Nav.Link>
                                                    <Nav.Link eventKey="link-2">EduTech</Nav.Link>
                                                    <Nav.Link eventKey="link-2">Print Media</Nav.Link>
                                                    <Nav.Link eventKey="link-2">Retail & E-commerce</Nav.Link>
                                                    <Nav.Link eventKey="link-2">Non-profit</Nav.Link>
                                                    {/* <Nav.Link eventKey="link-2">Public Services</Nav.Link> */}
                                                </Nav>
                                            </Col>
                                            <Col md={4}>
                                                <Nav defaultActiveKey="/home" className="flex-column">
                                                    <h5>Services</h5>
                                                    <Nav.Link href="/services/cloud-computing" eventKey="link-1">Cloud Services</Nav.Link>
                                                    <Nav.Link href="/services/enterprise-solution" eventKey="link-1">Enterprise Solutions</Nav.Link>
                                                    <Nav.Link href="/services/mobile-app-development" eventKey="link-2">Mobile App Development</Nav.Link>
                                                    <Nav.Link href="/services/web-app-development" eventKey="link-2">Web App Development</Nav.Link>
                                                    <Nav.Link href="/services/ux-cx-design" eventKey="link-2">UX/CX Design</Nav.Link>
                                                    <Nav.Link href="/services/it-consultancy" eventKey="link-1">IT Consultancy</Nav.Link>
                                                    {/* <Nav.Link href="/services/" eventKey="link-2">Servers & DevOps </Nav.Link>
                                                <Nav.Link href="/services/" eventKey="link-2">Interactive Thinking</Nav.Link> */}
                                                </Nav>
                                            </Col>
                                            <Col md={4}>
                                                <Nav defaultActiveKey="/home" className="flex-column">
                                                    <h5>Products</h5>
                                                    <Nav.Link eventKey="link-1"><strong>TeamFresh™</strong></Nav.Link>
                                                    <p>Behold, a new and creative way to collaborate is coming soon. Stay connected!</p>
                                                    {/* <Nav.Link eventKey="link-1">SoldWell™</Nav.Link>
                                                <Nav.Link eventKey="link-2">ZConnect™</Nav.Link>
                                                <Nav.Link eventKey="link-2">FindMy™</Nav.Link>
                                                <Nav.Link eventKey="link-2">GoLive™</Nav.Link>                                 */}
                                                </Nav>
                                            </Col> 
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Row>
            </Container>
            <Container className={"footer-bottom"} fluid>
                <Container className={styles.footerBottom}>
                    <Row>                        
                        <Col md={6}>
                            <span>&copy; 2023 Jasthink Softwares Private Limited</span>
                        </Col>
                        <Col md={6} className={cx('text-end')}>
                            <a className="" href="/privacy-policy">Privacy Policy</a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

export default Footer;