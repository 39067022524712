// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Collabs from '../../components/collabs';
import { Helmet } from "react-helmet";

import ServiceLinks from './serviceLinks';
import TextQuote from '../../components/textQuote';
import ContactForm from '../../components/contactForm';
import TrustedCustomers from "../../components/trustedCustomers"
import ProjectProcess from "../../components/projectProcess"
import AllServices from '../../components/ourServices';

// images 
import teamImage from "./assets/images/team.jpg";
import teamImageTwo from "./assets/images/team-two.jpg";

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function Services() {
    useEffect(() => {
        // document.title = `You clicked ${count} times`;
    });

    let serviceData = [
        {
            title: "Cloud<br />Computing",
            content: "Ensure scalability, security, and flexibility with our cloud solutions.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/cloud-network.gif",
            tileLnk: "/services/cloud-computing"
        },
        {
            title: "Technology<br />Consultancy",
            content: "Let's explore strategic insights to create road map for successful business goals.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/artificial-intelligence.gif",
            tileLnk: "/services/consultancy"
        },
        {
            title: "Enterprise<br />Solutions",
            content: "Transform your business operations with our comprehensive solutions.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/analytics.gif",
            tileLnk: "/services/enterprise-solution"
        },
        {
            title: "Customer<br />Experience Design",
            content: "We are dedicated to creating intuitive, visually appealing interfaces that engage your customers.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/responsive-design.gif",
            tileLnk: "/services/ux-cx-design"
        },
        {
            title: "Mobile App<br />Development",
            content: "Mobiles with seamless experiences that  that leave a lasting impression and engaging user satisfaction.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/payment-app.gif",
            tileLnk: "/services/mobile-app-development"
        },
        {
            title: "Web App<br />Development",
            content: "Collaboratively we build awesome wesites/applications that are fast, interactive and intutive.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/applications.gif",
            tileLnk: "/services/web-app-development"
        },
    ]     

    return (
        <>
            <Helmet>
                <title>Expert IT Services for Your Business | Jasthink Softwares</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Explore our comprehensive range of IT services including web development, mobile app development, DevOps solutions, quality assurance, project management, and more. Partner with Jasthink Softwares for innovative technology solutions tailored to your needs." />
                <meta name="keywords" content="IT services, web development, mobile app development, DevOps solutions, quality assurance, project management, software development, technology solutions, digital transformation, IT consulting"></meta>
            </Helmet>
            <section className={"main-section service-hero"}>
                <Container>
                    <Row>
                        <Col lg={1}></Col>
                        <Col md={7}>
                            <article>
                                <h4 className={"sub-head"}>IGNITE YOUR INNOVATION</h4>
                                <h1 className={"main-head mb-3"}>Collaboratively, let's bring your ideas to life!</h1>
                                <p>We go beyond simply being a service provider. We see ourselves as your partner in success. We take the time to understand your unique needs and challenges, working alongside you throughout the entire process - from initial concept to final execution.</p>
                                <p><strong>Ready to turn your vision into reality?</strong></p>
                            </article>
                            <a href={"/get-started"} className={"btn btn-primary"}>Get Started</a>
                        </Col>
                        <Col lg={1}></Col>
                        <Col md={3} className={"home-page-form"}>
                            {/* <ContactForm ctaTxt="Got a project in mind? Talk to our experts." subHeadTxt="" paraTxt="" buttonTxt="" submitLnk="" /> */}
                        </Col>
                    </Row>
                </Container>
            </section>
            <Collabs />
            <section className={"main-section-textquote pt-5"}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <TextQuote textColor="#741E7E" data="Let’s Discover how our team’s expertise and unwavering commitment to ethics can benefit your business."></TextQuote>
                        </Col>
                        <Col md={6}>
                            <img className={"team-image"} src={teamImage} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <AllServices />                        
            <section className={"main-section-team team-two-section"}>
                <Container>
                    <Row>
                        <Col md={7} className={"mx-auto text-center mb-5"}>
                            <h5 class="sub-head mt-4">REIMAGINE YOUR FUTURE</h5>
                            <h2 class="main-head">Drive your business growth through Innovation and Transformation</h2>
                        </Col>
                        <Col md={12}></Col>
                        <Col md={6}>
                            <img className={"team-two-image"} src={teamImageTwo} />
                        </Col>
                        <Col md={6}>
                            <div className={"ceo-content"}>
                                <p>The business landscape is constantly evolving. To stay ahead of the curve, you need a partner who can help you adapt, transform, and innovate. At JasThink, we're passionate about empowering businesses like yours to thrive in the digital age.</p>
                                <p>We partner with you to:</p>
                                <ul>
                                    <li><strong>Unlock Business Value:</strong> We help you identify and leverage opportunities to drive growth, efficiency, and profitability.</li>
                                    <li><strong>Build Resilience:</strong> We equip you with the tools and strategies to adapt to changing market conditions and overcome challenges.</li>
                                    <li><strong>Fuel Innovation:</strong> We foster a culture of innovation, helping you turn groundbreaking ideas into actionable solutions.</li>
                                </ul>
                                <p><strong>Embrace the Power of the Cloud:</strong> <br />A robust cloud strategy is essential for rapid scalability, breakthrough technologies, and seizing emerging opportunities.  We guide you in developing a customized cloud strategy that aligns perfectly with your business goals.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>    
            <section className={"main-section "}>
                <Container>
                    <Row className={"justify-content-center"}>
                        <Col md={7} className={"text-center pt-5 pb-5"}>
                            <h5 class="sub-head mt-5">UNLEASHING YOUR POTENTIAL</h5>
                            <h2 class="main-head">Expertise at Your Service</h2>
                            <p>JasThink goes beyond offering a one-size-fits-all approach. We take the time to understand your unique business challenges and objectives.  Through in-depth discussions and collaboration, we design IT solutions that are specifically tailored to address your needs.</p>                            
                            <div className={"service-icon"}>
                                <img src={"https://jasthinkassets.s3.amazonaws.com/services-assets/cloud-server.png"} />
                                <img className={"arrow-icon"} src={"https://jasthinkassets.s3.amazonaws.com/services-assets/next.png"} />
                                <img src={"https://jasthinkassets.s3.amazonaws.com/services-assets/app-development.png"} />
                            </div>
                            <p><strong>From cloud computing to IT consultancy,</strong><br /> JasThink offers a comprehensive suite of IT services and solutions tailored to your specific needs. Our team of dedicated experts brings extensive knowledge and a passion for delivering innovative solutions that:</p>
                        </Col>
                    </Row>
                    <Row className={"justify-content-center text-center"}>
                        <Col md={10}>
                            <Row>
                                <Col md={4}>
                                    <div className={"section-tiles"}>
                                        <img className={"section-img"} src={"https://jasthinkassets.s3.amazonaws.com/services-assets/enhance.svg"} />
                                        <h4 className={"title"}><strong>Enhance Efficiency</strong></h4>
                                        <p>Streamline your processes and operations for greater productivity and cost savings.</p>
                                    </div>                            
                                </Col>
                                <Col md={4}>
                                    <div className={"section-tiles"}>
                                        <img className={"section-img"} src={"https://jasthinkassets.s3.amazonaws.com/services-assets/rocket-lunch.svg"} />
                                        <h4 className={"title"}><strong>Boost Productivity</strong></h4>
                                        <p>Empower your workforce with the tools and technologies they need to excel.</p>
                                    </div>                            
                                </Col>
                                <Col md={4}>
                                    <div className={"section-tiles"}>
                                        <img className={"section-img"} src={"https://jasthinkassets.s3.amazonaws.com/services-assets/growth.svg"} />
                                        <h4 className={"title"}><strong>Drive Growth</strong></h4>
                                        <p>Achieve your business goals, unlock new revenue streams, and gain a competitive edge.</p>
                                    </div>                            
                                </Col>
                            </Row>                            
                        </Col>
                    </Row>
                    <Row className={"justify-content-center"}>
                        <Col md={7} className={"mb-5 mt-5 text-center"}>
                        <p>Our team of IT professionals possesses <strong>extensive knowledge</strong> across various industries. This deep understanding allows us to develop solutions that <strong>seamlessly integrate</strong> with your existing infrastructure and processes.  We leverage our experience to anticipate and address potential roadblocks, ensuring a <strong>smooth and successful</strong> implementation.</p>
                        </Col>
                    </Row>
                </Container>
            </section>      
            <TrustedCustomers subTitle={"with industries from all over the globe"} sectionTitle={"Our Exciting Collaborations"} />  
            <ProjectProcess />
        </>
    );
}

export default Services;