// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Styles from "./assets/styles.css";
import processImage from "./assets/images/process.png"

function projectProcess(props) {    
    const { data } =  props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        // Update the document title using the browser API        
    }); 
 
    return (
        <section className="main-section projectProcess">
            <Container>
                <Row>
                    <Col md={5}>
                        <h2>
                            Good team.<br />
                            <span>Clear process.</span><br />
                            <span>Successful Projects.</span><br />
                        </h2>
                        <p>Every person has weaknesses, however, we believe in people, the good in them and their strengths. The right direction and a mentor are all it requires to combine diverse experiences and strengths together to build a good team.</p>
                        <p><strong>Let's join the journey of Innovation and Transformation!</strong></p>
                        <a href="/get-started" className={"btn btn-primary"}>Get Started</a>
                    </Col> 
                </Row>
            </Container>
        </section>
    );
}
export default projectProcess;
