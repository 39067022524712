import React, { useRef, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomerTile from './customerTile';

import CallToAction from '../../components/callToAction';

// importing images
import styles from './assets/styles.css';
import IndustryCircles from './assets/images/industry-bubbles.svg'

function Industries() {
    // Ref for the slider element
    const sliderRef = useRef(null);
    const tilesRef = useRef([]);

    useEffect(() => {
        const handleClick = (event) => {
            const isActive = event.currentTarget.classList.contains('active');
            tilesRef.current.forEach((tile) => {
                tile.classList.remove('active');
            });
            if (!isActive) {
                event.currentTarget.classList.add('active');
            }
        };

        const tiles = tilesRef.current;
        tiles.forEach((tile) => {
            tile.addEventListener('click', handleClick);
        });

        return () => {
            tiles.forEach((tile) => {
                tile.removeEventListener('click', handleClick);
            });
        };
    }, []);

    // Function to handle beforeChange event
    const handleBeforeChange = (oldIndex, newIndex) => {
        // Check if sliderRef and innerSlider are defined
        if (sliderRef.current && sliderRef.current.innerSlider) {
            const currentSlide = sliderRef.current.innerSlider.list.querySelector(`[data-index="${oldIndex}"]`);
            const image = currentSlide.querySelector((newIndex > oldIndex) ? 'div.industry-name-tile' : 'div.industry-content-tile');
            image.classList.add((newIndex > oldIndex) ? 'parallax-left' : 'parallax-right');
        }
    };

    const handleAfterChange = (oldIndex, newIndex) => {
        // Get all div elements with class "parallax-left" or "parallax-right"
        const elements = document.querySelectorAll('.parallax-left, .parallax-right');

        // Loop through each element and remove the classes
        elements.forEach(element => {
            element.classList.remove('parallax-left', 'parallax-right');
        });
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
        responsive: [{
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }]
    };

    let IndustryData = [
        {
            title: "Virtual Tourism",
            content: "Craft immersive virtual travel experiences that bring destinations to life, captivating users and sparking wanderlust from their fingertips.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/virtual-tourism.png",
            tileLnk: "Virtual Tourism Application Development"
        },
        {
            title: "E-Learning",
            content: "Develop interactive e-learning platforms that empower learners with engaging content, personalized learning paths, and seamless accessibility.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/e-learning.png",
            tileLnk: "E-Learning application development"
        },
        {
            title: "Finance",
            content: "Engineer cutting-edge fintech solutions that revolutionize financial services, delivering secure, user-friendly experiences tailored to individual financial needs.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/crm-development.png",
            tileLnk: "Finacial application development"
        },
        {
            title: "E-Commerce",
            content: "Build robust e-commerce platforms that drive sales and customer loyalty through intuitive navigation, seamless transactions, and personalized recommendations.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/e-commerce.png",
            tileLnk: "E-Commerce Portal configuration and development"
        },
        {
            title: "Real Estate",
            content: "Create innovative real estate apps that simplify property search, streamline transactions, and provide valuable insights for buyers, sellers, and agents.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/housing-real-estate.png",
            tileLnk: "Real Estate Portal development"
        },
        {
            title: "Insurance",
            content: "Develop digital insurance solutions that automate processes, enhance customer engagement, and deliver peace of mind through comprehensive coverage options.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/insurance-marketplace.png",
            tileLnk: "Insurance Portal/App development"
        },
        {
            title: "Personal Growth",
            content: "Design transformative personal development apps that empower users to set goals, track progress, and access resources for continuous self-improvement.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/personal-growth.png",
            tileLnk: "Personal Growth application development"
        },
        {
            title: "Media &amp; Publishing",
            content: "Engineer dynamic media and publishing platforms that deliver rich content experiences, facilitate content creation and distribution, and engage audiences across diverse channels.",
            bgImage: "https://jasthinkassets.s3.amazonaws.com/images/industry/media-publishing.png",
            tileLnk: "Media & Publishing design and development"
        },
    ]

    return (
        <section className="main-section industries-block">
            <Container>
                <Row>
                    <Col md={10} className={"text-center mx-auto"}>
                        <h5 className="sub-head mt-4">SERVING DIVERSE INDUSTRIES</h5>
                        <h2 className={'main-head'}>Being result-oriented, <br />supporting a variety of sectors</h2>
                        <p>As a technology-partner with startups like yours, we glide through the development jungle, kniting together user-centric design and marketing strategies to capture your customers' heart. Forget about cookie-cutter solutions &mdash; we tailor your app to your unique vision, ensuring it leaves a lasting impact.</p>
                    </Col>
                </Row>
                <Row className={""}>
                    {IndustryData.map((tile, i) => (
                        <Col key={i} md={6} lg={4} className={"text-center"}>
                            <div className={"industry-tile"} ref={(el) => (tilesRef.current[i] = el)}>
                                <img className={"industry-tile-image"} src={tile.bgImage} />
                                <h3 className={"industry-tile-title mt-2"} dangerouslySetInnerHTML={{ __html: tile.title }}></h3>
                                <div className={"industry-content-tile"}>
                                    <a href="javascript:void(0);" className={"close-tile-info"}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"></path></svg></a>
                                    <p className={"industry-content"}>{tile.content}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                {/* <Row>
                    <Col md={9} className={"text-center mx-auto"}>                        
                        <h3>Ready to launch your Dream App?</h3>
                        <a href="/get-started" className={"btn btn-primary"}>Get Started</a>
                    </Col>
                </Row>                 */}
            </Container>
        </section >
    )
}

export default Industries;
