// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from "./assets/styles.css";

//images

function circleImage(props) {    
    const { data } =  props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let columnPlacement = (data.contentPlacement == "left" && windowWidth > 767) ? "order-md-2" : "";
    useEffect(() => {
        // Update the document title using the browser API        
    });

    const circleStyle = {
        backgroundColor: data.circleColor,
    }
    
    return (
        <div className={"imageSectionTile"}>
            <div className={"cont"} style={circleStyle}>
                <div className={"imgCont"}>
                    <img src={data.authorImg} />
                </div>
                {/* <div className={"author-msg peak-left-top"}>
                    <p dangerouslySetInnerHTML={{ __html: props.data.authorMessage }}></p>
                    <span><strong>{data.authorName}</strong>{ (data.designation != "") ? ", " + data.designation : "" }</span>
                </div> */}
            </div>
        </div>
    );
}
export default circleImage;
