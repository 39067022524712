// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from "./assets/styles.css";

//images

function circleSection(props) {    
    const { data } =  props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let columnPlacement = (data.contentPlacement == "left" && windowWidth > 767) ? "order-md-2" : "";
    useEffect(() => {
        // Update the document title using the browser API        
    });

    const circleStyle = {
        backgroundColor: data.circleColor,
    }
    
    return (
        <section className="main-section circleSection">
            <Container>
                <Row>
                    <Col md={6} className={"show-on-mobile"}>
                        <div dangerouslySetInnerHTML={{ __html: props.data.sectionContent }}></div>
                    </Col>
                    <spacer className={"show-on-mobile"}></spacer>
                    <Col md={6} className={columnPlacement}>
                        {/* <div className={"author-msg peak-left-top show-on-mobile"}>
                            <p dangerouslySetInnerHTML={{ __html: props.data.authorMessage }}></p>
                            <span><strong>{data.authorName}</strong>{ (data.designation != "") ? ", " + data.designation : "" }</span>
                        </div> */}
                        <div className={"sectionTile"}>
                            <div className={"cont"} style={circleStyle}>
                                <div className={"imgCont"}>
                                    <img src={data.authorImg} />
                                </div>
                                <div className={"author-msg peak-left-top"}>
                                    <p dangerouslySetInnerHTML={{ __html: props.data.authorMessage }}></p>
                                    <span><strong>{data.authorName}</strong>{ (data.designation != "") ? ", " + data.designation : "" }</span>
                                </div>
                            </div>
                        </div>                        
                    </Col>
                    <Col md={6} className={"show-on-desktop"}>
                        <div dangerouslySetInnerHTML={{ __html: props.data.sectionContent }}></div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default circleSection;
