// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";
// images 

// Styles and Css
import Styles from './assets/css/styles.css';
import cx from 'classnames';

function CaseStudies() {
    const [count, setCount] = useState(0);    
    const [metaData, setData] = useState(null);

    useEffect(() => {        
        setData(caseStudies[count]);   
    });

    const caseStudies = [
        {
            clientName: "Hashching",
            projectBrief: "In Australia's Fintech scene, we built a robust broker marketplace and launched a cutting-edge CRM for a startup—transforming visions into financial success.",
            clientNameInitial: "H.",
            companyName: "Hashching Ltd.",
            companyLocation: "NSW, Australia",
            projectServices: "DESIGN DIRECTION / PRODUCT DEVELOMENT",
            website: "hashching.com.au",
            companyBrief: "A key to stress-free mortgages in Australia! Imagine a place where you easily connect with trusted brokers, enjoy competitive rates, and make informed decisions. That's HashChing—your go-to platform, making home loan journeys simple, transparent, and tailored just for you. Welcome to a smarter way to mortgage!", 
            caseSlides : [
                {
                    image: "https://jasthinkassets.s3.amazonaws.com/casestudies/screen1.png",
                    title: "Exclusive Mortgage Deals from Brokers",
                    para: "Hashching not only offers exclusive deals but also provides a list of brokers available around a postcode in Australia, enhancing accessibility to financial expertise.",
                    statistics: [
                        {
                            image: "https://img.freepik.com/free-vector/app-development-concept-illustration_114360-5110.jpg?w=500&t=st=1702288441~exp=1702289041~hmac=5a944333f99019d5bd453fd7c0a83c021fa020a45c4c76c6b054c57ee9386243",
                            heading: "Pioneering the Online Mortgage Experience",
                            subHeading: "Strategic Partnership",
                            content: "<p><strong>Lead Generation, Mortgage, CRM, and Beyond</strong></p><p>In a landmark partnership, we joined forces with HashChing to pioneer a revolutionary online mortgage experience that has transformed the way Australians embark on their homeownership journey.</p><p>Our collaboration with HashChing represents a commitment to innovation and redefining industry standards. Together, we have seamlessly integrated cutting-edge technology and strategic insights to create an unparalleled online platform that prioritizes user-centricity.</p><p><span class='voilet'>We're innovating together, blending user-friendly design with cutting-edge tech for a seamless experience.</span></p><p>As trailblazers in this collaborative endeavor, our partnership with HashChing stands as a testament to the potential of strategic alliances in reimagining and elevating the online mortgage experience.</p>",
                        }
                    ],
                    closingNote: {
                        image: "https://img.freepik.com/free-vector/general-data-security-personal-information-protection-database-access-control-cyber-privacy-synchronized-gadgets-cross-platform-devices-regulation-vector-isolated-concept-metaphor-illustration_335657-2832.jpg?w=500&t=st=1702287974~exp=1702288574~hmac=81f1ee1078a3e7febd10a941364f09b072f70600452fc79fd19ccf794e45687e",
                        heading: "Secure and Streamlined Collaboration for Effortless Mortgage Interactions.",
                        content: "In our partnership, simplicity is key. We've intricately crafted an online mortgage experience on AWS, seamlessly connecting brokers and customers. This ensures transparency, efficiency, and empowerment, fortified by AWS cloud computing for unparalleled security in safeguarding your valuable data.",
                    },
                    screenShot: "https://jasthinkassets.s3.amazonaws.com/casestudies/home-page.jpg",                   
                }
            ],
            conclusion: {
                heading: "Hashching's story isn't just about convenience and efficiency...",
                content:"<p><strong>It's about the overall partnership that propelled the project to greatness.</strong></p><p>We as a technology parther to Hashching, we streamlined the process of development and support, fostered a competitive environment, and created an ultimate platform for Loan providers and seekers.</p><p>The commitment to quality and customer satisfaction has been recognized with numerous awards, solidifying its position as the undisputed marketplace for lending leaders.</p><p>Today, Hashching stands tall as a testament to the power of collaboration and technological innovation.</p><p>The journey, from a budding startup to a trusted marketplace, embodies the transformative potential of technology and a shared vision. As they continue to evolve and expand, Hashching promises to remain a driving force in shaping the future of home services in Australia, forever changing the game for both consumers and professionals.</p>"
            },
            metaTitle: "Inspiring Case Studies Highlighting Our Success Stories | Jasthink Softwares",
            metaDesc: "Explore our collection of case studies showcasing our successful projects and satisfied clients. Discover how Jasthink Softwares delivers innovative solutions and drives business growth through technology.",
            metakeywords: "Case studies, success stories, client testimonials, project highlights, business solutions, technology success, case study examples, project achievements, Jasthink Softwares case studies"
        },
        {
            clientName: "Georama",
            projectBrief: "In Australia's Fintech scene, we built a robust broker marketplace and launched a cutting-edge CRM for a startup—transforming visions into financial success.",
            clientNameInitial: "H.",
            companyName: "Hashching Ltd.",
            companyLocation: "NSW, Australia",
            projectServices: "DESIGN DIRECTION / PRODUCT DEVELOMENT",
            website: "hashching.com.au",
            companyBrief: "A key to stress-free mortgages in Australia! Imagine a place where you easily connect with trusted brokers, enjoy competitive rates, and make informed decisions. That's HashChing—your go-to platform, making home loan journeys simple, transparent, and tailored just for you. Welcome to a smarter way to mortgage!", 
            caseSlides : [
                {
                    image: "https://stock.adobe.com/in/images/young-male-freelancer-holding-blank-screen-tablet/283441919",
                    title: "Mortgage Deals: Transparent Mortgage Comparisons",
                    para: "HashChing ensures clarity, empowering users with transparent deal comparisons for informed decisions for loan journey.",                
                    statistics: [
                        {
                            image: "https://img.freepik.com/free-vector/app-development-concept-illustration_114360-5110.jpg?w=500&t=st=1702288441~exp=1702289041~hmac=5a944333f99019d5bd453fd7c0a83c021fa020a45c4c76c6b054c57ee9386243",
                            heading: "Pioneering the Online Mortgage Experience",
                            subHeading: "Strategic Partnership",
                            content: "<p><strong>Lead Generation, Mortgage, CRM, and Beyond</strong></p><p>In a landmark partnership, we joined forces with HashChing to pioneer a revolutionary online mortgage experience that has transformed the way Australians embark on their homeownership journey.</p><p>Our collaboration with HashChing represents a commitment to innovation and redefining industry standards. Together, we have seamlessly integrated cutting-edge technology and strategic insights to create an unparalleled online platform that prioritizes user-centricity.</p><p><span class='voilet'>We're innovating together, blending user-friendly design with cutting-edge tech for a seamless experience.</span></p><p>As trailblazers in this collaborative endeavor, our partnership with HashChing stands as a testament to the potential of strategic alliances in reimagining and elevating the online mortgage experience.</p>",
                        }
                    ],
                    closingNote: {
                        image: "https://img.freepik.com/free-vector/general-data-security-personal-information-protection-database-access-control-cyber-privacy-synchronized-gadgets-cross-platform-devices-regulation-vector-isolated-concept-metaphor-illustration_335657-2832.jpg?w=500&t=st=1702287974~exp=1702288574~hmac=81f1ee1078a3e7febd10a941364f09b072f70600452fc79fd19ccf794e45687e",
                        heading: "Secure and Streamlined Collaboration for Effortless Mortgage Interactions.",
                        content: "In our partnership, simplicity is key. We've intricately crafted an online mortgage experience on AWS, seamlessly connecting brokers and customers. This ensures transparency, efficiency, and empowerment, fortified by AWS cloud computing for unparalleled security in safeguarding your valuable data.",
                    },
                    screenShot: "https://jasthinkassets.s3.amazonaws.com/casestudies/home-page.jpg",                    
                }
            ],
            conclusion: {
                heading: "Hashching's story isn't just about convenience and efficiency...",
                content:"<p><strong>It's about the overall partnership that propelled the project to greatness.</strong></p><p>We as a technology parther to Hashching, we streamlined the process of development and support, fostered a competitive environment, and created an ultimate platform for Loan providers and seekers.</p><p>The commitment to quality and customer satisfaction has been recognized with numerous awards, solidifying its position as the undisputed marketplace for lending leaders.</p><p>Today, Hashching stands tall as a testament to the power of collaboration and technological innovation.</p><p>The journey, from a budding startup to a trusted marketplace, embodies the transformative potential of technology and a shared vision. As they continue to evolve and expand, Hashching promises to remain a driving force in shaping the future of home services in Australia, forever changing the game for both consumers and professionals.</p>"
            }
        },
    ];

    const lengthCases = caseStudies.length;
    
    const [isNext, setIsNext] = useState({ isVisible: true, title: "" });
    const [isPrevious, setIsPrevious] = useState({ isVisible: false, title: "" });

    const handleNext = () => {
      const newCount = count + 1;      
      setCount(newCount);

      let lnkTitle = "";
      if(count < lengthCases)
      lnkTitle = caseStudies[count+1].clientName;
      else
      lnkTitle = "";

      setIsNext({ isVisible: newCount < lengthCases, title: lnkTitle });
      setIsPrevious({ isVisible: newCount > 0, title: ((isPrevious.isVisible) ? caseStudies[count-1].clientName : "") });
    };
  
    const handlePrevious = () => {
      const newCount = count - 1;
      setCount(newCount);
      
      let lnkTitle = "";
      if(count > 0)
      lnkTitle = caseStudies[count+1].clientName;
      else
      lnkTitle = "";

      setIsNext({ isVisible: newCount < lengthCases, title: ((isNext.isVisible) ? caseStudies[count+1].clientName : "") });
      setIsPrevious({ isVisible: newCount > 0, title: lnkTitle });
    };

    useEffect(() => {
        
    });

    return (
        <>
        {metaData && (
            <Helmet>
                <title>{ metaData.metaTitle }</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content={ metaData.metaDesc } />        
                <meta name="keywords" content={ metaData.metakeywords }></meta>
            </Helmet>
        )}
            <section className={"bannerCircles"}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </section>

            <Container className={"caseStudyHead"}>
                <Row>
                    <Col md={6}>
                        <article>
                            <h3 className={"clientName"}>{caseStudies[count].clientName}</h3>
                            <p dangerouslySetInnerHTML={{ __html: caseStudies[count].projectBrief }}></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.666" height="11.833" viewBox="0 0 21.666 11.833">
                                <path id="Path_355" data-name="Path 355" d="M-5004,6611.9l9.419,9.419,9.419-9.419" transform="translate(5005.414 -6610.481)" fill="none" stroke="#741e7e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </svg>
                        </article>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={2}>
                                <h2 className={"clientNameInitial"}>{caseStudies[count].clientNameInitial}</h2>
                            </Col>
                            <Col md={8}>
                                <strong>{caseStudies[count].companyName} &mdash; {caseStudies[count].companyLocation}</strong>
                                <p><small>{caseStudies[count].projectServices}</small></p>
                                <p><strong>{caseStudies[count].website}</strong><br />
                                    <small>{caseStudies[count].companyBrief}</small></p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            
            <section className={"slideShow"}>
                <div></div>
                <div className={"centerTile"}>
                    <div className={"slideScreen"}><img src={caseStudies[count].caseSlides[0].image} /></div>
                    <Container className={"textContent"} fluid>
                        <Row>
                            <Col md={5} className={"textTitle"}>
                                <p dangerouslySetInnerHTML={{ __html: caseStudies[count].caseSlides[0].title }}></p>
                            </Col>
                            <Col md={7}>
                                <p dangerouslySetInnerHTML={{ __html: caseStudies[count].caseSlides[0].para }}></p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div></div>
                {/* <p className={"slide-dots"}>
                    <span className={"active"}></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </p> */}
            </section>
            
            <Container className={"case-study-analytics"}>
                <Row>
                    <Col md={6}>
                        <img src={caseStudies[count].caseSlides[0].statistics[0].image}/>
                    </Col>
                    <Col md={6}>
                        <h4 className={"sub-title"}>{caseStudies[count].caseSlides[0].statistics[0].subHeading}</h4>
                        <h2 className={"sec-title"}><srtrong>{caseStudies[count].caseSlides[0].statistics[0].heading}</srtrong></h2>
                        <p dangerouslySetInnerHTML={{ __html: caseStudies[count].caseSlides[0].statistics[0].content }}></p>
                    </Col>
                </Row>
            </Container>
            <Container className={"case-study-analytics sub-analytical"}>
                <Row className={"justify-content-center text-center"}>
                    <Col md={6}>
                        <img src={caseStudies[count].caseSlides[0].closingNote.image} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                    <h2 className={"sec-title"}><srtrong dangerouslySetInnerHTML={{ __html: caseStudies[count].caseSlides[0].closingNote.heading }}></srtrong></h2>
                    </Col>
                    <Col md={6}>                                        
                        <p dangerouslySetInnerHTML={{ __html: caseStudies[count].caseSlides[0].closingNote.content }}></p>                        
                    </Col>
                </Row>
            </Container>
            <section className={"screen-shots"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 1440 895.999">
                    <defs>
                        <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 996 560">
                            <image width="996" height="560" href="https://jasthinkassets.s3.amazonaws.com/casestudies/ipad-screenshot.jpg" />
                        </pattern>
                        <pattern id="pattern-2" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 835 587">                            
                            <image id="img_screenshot" width="835" height="587" href={ caseStudies[count].caseSlides[0].screenShot }/>
                        </pattern>
                    </defs>
                    <g id="Group_204" data-name="Group 204" transform="translate(0 -2669)">
                        <path id="Subtraction_11" data-name="Subtraction 11" d="M5699-5332H5421a216.771,216.771,0,0,1-67.9-10.834A216.1,216.1,0,0,1,5294-5373.026v-351.947a216.073,216.073,0,0,1,59.105-30.192A216.767,216.767,0,0,1,5421-5766h278a218.61,218.61,0,0,1,43.732,4.409,215.914,215.914,0,0,1,40.733,12.644,217.025,217.025,0,0,1,36.86,20.007,218.494,218.494,0,0,1,32.115,26.5,218.494,218.494,0,0,1,26.5,32.115,217.025,217.025,0,0,1,20.007,36.86,215.892,215.892,0,0,1,12.644,40.733A218.61,218.61,0,0,1,5916-5549a218.623,218.623,0,0,1-4.409,43.733,215.9,215.9,0,0,1-12.644,40.733,216.979,216.979,0,0,1-20.007,36.86,218.494,218.494,0,0,1-26.5,32.115,218.494,218.494,0,0,1-32.115,26.5,217.006,217.006,0,0,1-36.86,20.007,215.914,215.914,0,0,1-40.733,12.644A218.61,218.61,0,0,1,5699-5332Z" transform="translate(-5294 8897)" fill="#f0eeef" opacity="0.245"/>
                        <path id="Subtraction_12" data-name="Subtraction 12" d="M5619-5397H5341a218.615,218.615,0,0,1-43.733-4.409,215.927,215.927,0,0,1-40.733-12.644,217.042,217.042,0,0,1-36.86-20.007,218.545,218.545,0,0,1-32.115-26.5,218.516,218.516,0,0,1-26.5-32.115,217.067,217.067,0,0,1-20.007-36.86,215.914,215.914,0,0,1-12.644-40.733A218.61,218.61,0,0,1,5124-5614a218.615,218.615,0,0,1,4.409-43.733,215.914,215.914,0,0,1,12.644-40.733,217.042,217.042,0,0,1,20.007-36.86,218.565,218.565,0,0,1,26.5-32.115,218.545,218.545,0,0,1,32.115-26.5,217.042,217.042,0,0,1,36.86-20.007,215.927,215.927,0,0,1,40.733-12.644A218.615,218.615,0,0,1,5341-5831h278c6.667,0,13.4.306,20,.909v432.181C5632.393-5397.306,5625.664-5397,5619-5397Z" transform="translate(-4199 8500)" fill="#fff" opacity="0.245"/>
                        <rect id="Image_41" data-name="Image 41" width="996" height="560" rx="280" transform="translate(222 2858)" fill="url(#pattern)"/>
                        <rect id="Image_44" data-name="Image 44" width="557" height="391" rx="15" transform="translate(443 2943)" fill="url(#pattern-2)"/>
                    </g>
                </svg>
            </section>
            <Container className={"conclusion"}>
                <Row className={"justify-content-center"}>
                    <Col md={6}>
                        <h3><strong>{caseStudies[count].conclusion.heading}</strong></h3><br />
                        <div dangerouslySetInnerHTML={{ __html: caseStudies[count].conclusion.content }}></div>
                    </Col>
                </Row>
            </Container>
            <section  className={"company-statement"}>
                <Container>
                    {/* <Row className={"justify-content-center text-center"}>
                        <Col md={6}>
                            <img src={"https://img.freepik.com/free-vector/product-quality-concept-illustration_114360-7301.jpg"} width="300" />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={6}>
                            <h2 className={"sec-title"}><strong>Empowering Futures, Enabling Excellence &mdash; Where Innovation Meets Impact.</strong></h2>
                            <a href="/get-started" class="btn btn-primary mt-5 nav-link">Ready to get started?</a>
                        </Col>
                        <Col md={6}>                                        
                            <p>With over 15 years of profound experience in the IT industry, our esteemed IT services firm, headquartered in Chandigarh, India, specializes in delivering top-notch solutions across web and mobile design and development, DevOps, Quality Assurance, and Project Management services. We take pride in our commitment to excellence, underpinned by a deep understanding of the ever-evolving technological landscape.</p>
                            <h5><strong></strong></h5>                                                
                            <p>We're pleased to share that our company has been acknowledged on DesignRush, a prestigious listing platform showcasing top-tier IT agencies. Explore <a href="https://www.designrush.com/agency/profile/jasthink-softwares" target="_blank">our profile here</a> to gain insights into our industry standing. While the platform may not provide a comprehensive portfolio, the recognition on DesignRush is a testament to our dedication to innovation and positions us among the elite agencies in the competitive IT landscape. This acknowledgment reaffirms our commitment to delivering cutting-edge services and highlights our prominence in the industry.</p>                        
                        </Col>
                    </Row>
                </Container>
            </section> 
        </>
    );
}

export default CaseStudies;