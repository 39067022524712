import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import AnimatedCircles from '../animatedCirlcesLoader';

import Styles from './assets/styles.css';

function contactForm(props) {
    const { ctaTxt, subHeadTxt, paraTxt, buttonTxt, submitLnk } = props;
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        message: '',
    });

    const handleReset = () => {
        // Reset the form data to its initial state
        setFormData({
            fullName: '',
            email: '',
            mobileNumber: '',
            message: '',
        });
    };


    const [validationErrors, setValidationErrors] = useState({});
    const [submissionError, setSubmissionError] = useState(null);
    const [showLoader, setshowLoader] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const validateMobileNumber = (mobileNumber) => {
        // Assuming a simple validation where the mobile number should consist of 10 digits.
        const mobileNumberRegex = /^\d{10}$/;
        return mobileNumberRegex.test(mobileNumber);
    };

    const isMessageValid = (message) => {
        // Split the message into words using whitespace as the separator
        const words = message.split(/\s+/);

        // Filter out empty strings (caused by extra spaces) from the array
        const filteredWords = words.filter(word => word.trim() !== '');

        // Check if the number of words is greater than 50
        return (filteredWords.length > 50 && filteredWords.length < 250);
    };

    const validateName = (name) => {
        // Regular expression to allow alphabets, hyphens, and spaces.
        const nameRegex = /^[A-Za-z\- ]{3,}$/
        return nameRegex.test(name);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { fullName, email, mobileNumber, message } = formData;

        // Perform validation here (e.g., check for required fields, valid email, etc.)
        const newValidationErrors = {};
        if (!fullName) {
            newValidationErrors.fullName = 'Full name is required';
        } else if (!validateName(fullName)) {
            newValidationErrors.fullName = 'Name should be atleast 3 character long and, only alphabets, hyphen, spaces are allowed. ';
        }

        if (!email) {
            newValidationErrors.email = 'Email is required';
        } else if (!validateEmail(email)) {
            newValidationErrors.email = 'Please enter a valid email address.';
        }
        if (!mobileNumber) {
            newValidationErrors.mobileNumber = 'Mobile number is required';
        } else if (!validateMobileNumber(mobileNumber)) {
            newValidationErrors.mobileNumber = 'Invalid mobile number format. Should be atleast 10 digit number';
        }

        if (!message) {
            newValidationErrors.message = 'Message is required';
        } else if (!isMessageValid(message)) {
            // Split the message into words using whitespace as the separator
            const mWords = message.split(/\s+/);

            // Filter out empty strings (caused by extra spaces) from the array
            const filteredMWords = mWords.filter(word => word.trim() !== '');

            if (filteredMWords.length < 50) {
                newValidationErrors.message = 'Please briefly describe your message in atlest 50 words.';
            } else if (filteredMWords.length > 250) {
                newValidationErrors.message = 'Exceeds limit by ' + (filteredMWords.length - 250) + ' words. Please keep your message under 250 words.';
            }
        }
        // Add more validation checks for mobileNumber and message as needed

        if (Object.keys(newValidationErrors).length > 0) {
            setValidationErrors(newValidationErrors);
        } else {
            setshowLoader(true);
            // If validation passes, make the AJAX call
            const data = {
                fullName,
                email,
                mobileNumber,
                message,
            };

            axios
                .post('https://8435ns4vq9.execute-api.us-east-1.amazonaws.com/production/contact', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Add any security headers or tokens here
                    },
                })
                .then((response) => {
                    // Handle the successful response from Lambda
                    console.log('Submission successful', response.data);
                    setSubmissionError(null);
                    const contactFormTag = document.getElementById("contactForm");
                    const thankyouMessage = document.getElementById("thankYouMessage");
                    contactFormTag.style.display = "none";
                    thankyouMessage.style.display = "block";
                    handleReset();
                    setshowLoader(false);
                })
                .catch((error) => {
                    // Handle any errors that occur during the AJAX request
                    console.error('Submission error', error);
                    setSubmissionError('Error submitting the form');
                    setshowLoader(false);
                });
        }
    };

    return (
        <Card>            
            <Card.Body>                                
                <Form id="contactForm" onSubmit={handleSubmit} noValidate>
                    <Card.Title className="text-center">
                        <h5>{subHeadTxt}</h5>
                        <strong>{ctaTxt}</strong>
                        <p>&mdash;&mdash;&mdash;</p>
                    </Card.Title>
                    <Card.Text>{paraTxt}</Card.Text>
                    <Form.Group className="mb-3" controlId="fullName">
                        <Form.Label>Full name</Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            placeholder="John Doe"
                        />
                        {validationErrors.fullName && (
                            <div className="text-danger text-sm">{validationErrors.fullName}</div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="email@domain.com"
                        />
                        {validationErrors.email && (
                            <div className="text-danger">{validationErrors.email}</div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="mobileNumber">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                            type="tel"
                            name="mobileNumber"
                            placeholder="+1 (222) 333 4444"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                        />
                        {validationErrors.mobileNumber && (
                            <div className="text-danger">{validationErrors.mobileNumber}</div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="message">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={6}
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            placeholder="Write your message here..."
                        />
                        {validationErrors.message && (
                            <div className="text-danger">{validationErrors.message}</div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3 text-center">
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        {submissionError && (
                            <div className="text-danger">{submissionError}</div>
                        )}
                    </Form.Group>
                </Form>
                <div id="thankYouMessage" className={"text-center thank-you-message"}>
                    <h3><strong>Thank you for choosing us!</strong></h3>
                    <p></p>
                    <p>We're delighted to have you on our list of prestigious clients. Our experts will reach out to you within the next 24 hours. Welcome aboard!</p>
                </div>
                {showLoader ? (
                    <AnimatedCircles  /> 
                ) : ""}
            </Card.Body>
        </Card>
    )
}

export default contactForm;
