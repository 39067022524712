// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from "./assets/styles.css";

//images

function fillerBox(props) {    
    const { backgroundColor, textColor } =  props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        // Update the document title using the browser API        
    });

    const fillerBg = {
        backgroundColor: backgroundColor,
        color:textColor
    }
    
    return (
        <section className="fillerBox">
            <Container>
                <div className="cont" style={fillerBg}>                
                    <Row>
                        <Col md={6} className={"show-on-desktop"}><p>Things have evolved quickly since we started, nearly a decades ago, but here’s what’s stayed the same:</p></Col>
                        <Col md={6} className={"text-center text-quote"}><p>An ambitious team, <strong>succeeding by delivering</strong> innovative digital experiences and providing solutions to customers</p></Col>
                    </Row>                
                </div>        
            </Container>    
        </section>
    );
}
export default fillerBox;