// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet";

//Components
import CircleSection from '../../components/circleSection';
import ProjectProcess from '../../components/projectProcess';
import FillerBox from '../../components/fillerBox';
import ContactForm from '../../components/contactForm';
import CallToAction from '../../components/callToAction';

// images 

// Styles and Css
import cx from 'classnames';
import styles from './assets/aboutStyles.module.css';
import officeRoom from './assets/images/hero-banner-office.jpg';
import meetingRoom from './assets/images/hero-banner-meeting.jpg'


function About() {    
    useEffect(() => {
        // Update the document title using the browser API
        
    });

    const circleData = [
        {
            circleColor: "#f7e6ff55",
            contentPlacement: "right",
            authorName:"Kawaljit Singh",
            authorImg: "https://jasthinkassets.s3.amazonaws.com/kawal-image.jpg",
            designation:"CEO",
            authorMessage:"<strong>We are good in it, driving a positive change</strong> for the benefit of the society. Together, we combine innovation and collective knowledge to create services or apps for all.",
            sectionContent:"<h3><strong>End-to-end project management</strong>, analysis, data visualisation, cloud computing, creatives, UX/CX and digital marketing services.</h3><br /><p>Our collaborative approach goes beyond traditional client-vendor relationships. We're more than partners; we're co-creators, weaving your aspirations into every line of code and every pixel of design.</p>",
            footNote:""
        },
        {
            circleColor: "#f7e6ff55",
            contentPlacement: "left",
            authorName:"Harpreet Kaur",
            authorImg: "https://jasthinkassets.s3.amazonaws.com/harpreet-image.jpg",
            designation:"COO",
            authorMessage:"<strong>We believe our people make the difference.</strong> We nurture to develope opportunities for learning and a mutual growth for all.",
            sectionContent:"<h2><strong>Our belief invigorate us to build.</strong></h2>Since our inception, we partner with rising or established organisations with a belief — to collaborate for a greater future. We innovate and generate new creative ideas with open minds and finding the best possible customer experience.",
            footNote:"Innovation"
        }, 
        {
            circleColor: "#f7e6ff55",
            contentPlacement: "right",
            authorName:"",
            authorImg: "https://jasthinkassets.s3.amazonaws.com/meeting-room.jpg",
            designation:"",
            authorMessage:"<strong>We team up together</strong> for agile, requirement-lead, co-located design-develop-verify process for on-time delivery and successful launch.",
            sectionContent:"<h2><strong>Best be agile!</strong></h2><p>Behavioural change in the system is not always easy to implement. It is even hard to manage it when the outcome of an action may have a scale of one to measure. Being Agile makes it easy to plan, build, run, test, fix, verify and launch engaging customer experiences for society.</p><p> Together, we navigate the intricacies of technological advancements, ensuring that the solutions we build today stand resilient in the face of tomorrow's challenges. Join us in this journey of innovation and transformation.</p>",
            footNote:"Innovation"
        }, 
    ];
    
    return (
        <>
            <Helmet>
                <title>Discover Jasthink Softwares: Leaders in Innovative IT Solutions | About Us</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Learn about Jasthink Softwares' journey and commitment to delivering cutting-edge IT services. Explore our expertise in web development, mobile design, DevOps, and more. Join us as we pave the way for technological advancement." />        
                <meta name="keywords" content="Jasthink Softwares, Company history, Vision and mission, Core values, Team expertise, Client testimonials, Industry experience, Awards and recognition, Company culture, Community involvement"></meta>
            </Helmet>
        
            <div className={"hide-overflow"}>
                {/* <section className={"main-section service-hero"}>
                    <Container>
                        <Row>
                            <Col lg={1}></Col>
                            <Col md={5}>
                                <h4 className={"sub-head"}>ABOUT US</h4>
                                <h1 className={"main-head mb-3"}>Crafted with precision, the perfect products for your needs.</h1>
                                <p>Experience the heart and soul in our finely crafted products, uniquely designed to cater to your needs. Each piece is a labor of love, meticulously created for utmost quality and your utmost satisfaction. Find your perfect match in a product that cares.</p>                            
                                <a href={"/get-started"} className={"btn btn-primary outlined-btn"}>Get Started</a>
                            </Col>
                            <Col lg={1}></Col>
                            <Col md={4} className={"home-page-form"}>
                                <ContactForm ctaTxt="Let's team up to develope best app!" subHeadTxt="Ready for the magic? " paraTxt="" buttonTxt="" submitLnk="" />
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                <section className={cx("main-section about-us", styles.heroSection)}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <article>
                                    <h1>We converse, visualise & build future-ready solutions for you, today.</h1>
                                    {/* <p>So, why wait? Let us join and be partners in the technological evolution and deliver the utmost customer experience in digital-transformation projects.</p> */}
                                    <p>Embrace the future today. Engage with us in dynamic conversations, where we not only visualize but bring to life innovative, future-ready solutions. Let's forge a partnership, navigating the technological evolution together, and ensure unparalleled customer experiences in every digital transformation venture we embark upon. Don't delay, let's shape tomorrow now.</p>                            
                                </article>
                            </Col>
                            <Col md={6} className={styles.imageSection}>
                            <img src={officeRoom} className={styles.officeSpace} alt="JasThink Softwares" />
                            <img src={meetingRoom} className={styles.meetingRoom} alt="Team discusions" />  
                            </Col>
                        </Row>
                    </Container>
                </section>              
                <CircleSection data={circleData[0]} />
                <FillerBox backgroundColor="#741E7E" textColor="#fff" />
                <CircleSection data={circleData[1]} />
                <CircleSection data={circleData[2]} />             
                <ProjectProcess />  
            </div>
        </>
    );
}

export default About;