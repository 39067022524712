// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images 

// Styles and Css
import Styles from './assets/css/styles.module.css';
import cx from 'classnames';

function UnderConstruction() {     
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        document.title = `Jasthink | Products`;
    });
    
    return (
        <>
            <Container>
                <section className={ cx(Styles.ucBanner) }>
                    <Row>
                        <Col md={8}>
                            This page is under construction.
                        </Col>
                        <Col md={4}>
                            column 2
                        </Col>
                    </Row>
                </section>
            </Container>
        </>
    );
}

export default UnderConstruction;