import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill styles
import { Container, Button, Form, Col, Row, CardGroup } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { Helmet } from "react-helmet";
import AnimatedCircles from "../../components/animatedCirlcesLoader"

import ProjectProcess from '../../components/projectProcess';
import TrustedCustomers from '../../components/trustedCustomers';

//Defining styles for the form
import cx from 'classnames';
import styles from "./assets/getStartedStyles.css"


const getStarted = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    businessName: '',
    contactEmail: '',
    contactMobile: '',
    projectType: '',
    ongoingOptions: '',
    industry: '',
    projectDescription: '',
    // projectFile: null,
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [showLoader, setshowLoader] = useState(false);

  const modalRef = useRef();

  const validateForm = () => {
    const errors = {};

    if (!formData.fullName || !/^[a-zA-Z -]+$/.test(formData.fullName)) {
      errors.fullName = 'Please enter valid name.';
    }
    if (!formData.businessName) errors.businessName = 'Please provide your business name. If personal, enter "individual".';
    if (!isValidEmail(formData.contactEmail)) errors.contactEmail = 'Email is key communication channel, please enter a valid email.';
    if (!isValidMobile(formData.contactMobile)) errors.contactMobile = 'Please enter a valid mobile number.';
    if (!formData.projectType) errors.projectType = 'Please select one option.';
    if (!formData.ongoingOptions) errors.ongoingOptions = 'Please select one option.';
    if (!formData.industry) errors.industry = 'Please select one option.';
    if (!formData.projectDescription) errors.projectDescription = 'Please select one option.';
    // if (!formData.projectFile) errors.projectFile = 'Project File is required';
    // if (
    //   formData.projectFile &&
    //   !isValidFileFormat(formData.projectFile)
    // ) {
    //   errors.projectFile = 'Invalid file format';
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isValidEmail = (email) => {
    // Basic email validation
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const isValidMobile = (mobile) => {
    // Basic mobile number validation (10 digits)
    return /^\+?\d{1,4}\d{10}$/.test(mobile);
  };

  const isValidFileFormat = (file) => {
    // Check if the file format is allowed (PDF, Word, JPG, or ZIP)
    const allowedFormats = ['application/pdf', 'application/msword', 'image/jpeg', 'application/zip'];
    return allowedFormats.includes(file.type);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, projectFile: file });
  };

  const handleEditorChange = (value) => {
    setFormData({ ...formData, projectDescription: value });
  };

  const sanitizedHTML = () => {
    const div = document.createElement('div');
    div.innerText = input;
    return { __html: div.innerHTML };
  };

  const handleReset = () => {
    // Reset the form data to its initial state
    setFormData({
      fullName: '',
      businessName: '',
      contactEmail: '',
      contactMobile: '',
      projectType: '',
      ongoingOptions: '',
      website: '',
      industry: '',
      projectDescription: '',
    });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    if (validateForm()) {
      var { fullName,
        businessName,
        contactEmail,
        contactMobile,
        projectType,
        ongoingOptions,
        website,
        industry,
        projectDescription, } = formData;
      
      var data = {
        fullName,
        businessName,
        contactEmail,
        contactMobile,
        projectType,
        website,
        ongoingOptions,
        industry,
        projectDescription,
      };

      setshowLoader(true);
      axios
        .post('https://8435ns4vq9.execute-api.us-east-1.amazonaws.com/production/get-started', data, {
          headers: {
            'Content-Type': 'application/json',
            // Add any security headers or tokens here
          },
        })
        .then((response) => {
          // Handle the successful response from Lambda
          console.log('Submission successful', response.data);
          setSubmissionError(null);
          const contactFormTag = document.getElementById("getStartedForm");
          const thankyouMessage = document.getElementById("thankYouMessage");
          contactFormTag.style.display = "none";
          thankyouMessage.style.display = "block";
          handleReset();
          setshowLoader(false);
        })
        .catch((error) => {
          // Handle any errors that occur during the AJAX request
          console.error('Submission error', error);
          setSubmissionError('Error submitting the form');
          setshowLoader(false);
        });
      setIsSubmitting(false);
      setShowModal(false); // Close the modal after successful submission
    } else {
      setIsSubmitting(false);
      setshowLoader(false);
    }
  };

  const hideStartedForm = () => {
    const getStartedDiv = document.getElementById("getStarted");
    if (getStartedDiv) {
      getStartedDiv.classList.remove("get-started-show");
    }
  }

  const circleData = [
    {
      circleColor: "#DEC3EA55",
      contentPlacement: "right",
      authorName: "Kawaljit Singh",
      authorImg: "https://jasthinkassets.s3.amazonaws.com/team-member.jpg",
      designation: "CEO",
      authorMessage: "<strong>We are good in it, driving a positive change</strong> for the benefit of the society. Together, we combine innovation and collective knowledge to create services or apps for all.",
      sectionContent: "<h3><strong>End-to-end project management</strong>, analysis, data visualisation, cloud computing, creatives, UX/CX and digital marketing services.</h3>",
      footNote: ""
    },
  ];

  return (
    
    <>
      <Helmet>
          <title>Get Started with Jasthink Softwares: Streamline Your IT Solutions Today!</title>
          <link rel="icon" href="/favicon.png" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow" />
          <meta name="description" content="Ready to revolutionize your IT infrastructure? Complete our 'Get Started' form for tailored solutions in web development, mobile design, DevOps, and more. Partner with Jasthink Softwares today!" />        
          <meta name="keywords" content="IT services, Web development, Mobile app development, DevOps solutions, Quality assurance, Project management, Software development, Technology solutions, Digital transformation, IT consulting"></meta>
      </Helmet>
      <section className={'get-started-form'}>
        <Container>
          <hr className="spacer" />
          <Row>
            <Col lg={6}>
              <hr className="spacer" />
              <article>
                <h3><strong>Contact us today to learn how we can elevate your experience and exceed your expectations.</strong></h3>
                <span className="divider"></span>
                <p>Welcome to our world-class service offerings designed with you in mind. At Jasthink, we take pride in providing top-tier solutions to address your specific needs.</p>
                <p>Our dedicated team of experts is committed to delivering excellence in every aspect of our services. Whether you're looking for tailored solutions, unmatched quality, or unmatched support, we've got you covered.</p>
                <p>We understand that every client is unique, and that's why we offer a wide range of services to ensure your satisfaction. Let us take care of your needs and help you achieve your goals.</p>
              </article>
            </Col>
            <Col lg={6}>
              <Card>
                <Card.Body>
                  <Form id="getStartedForm">
                    <h2 className="get-started-header">Get Started</h2>
                    <Form.Group className={"form-group"}>
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="businessName"
                        placeholder="XYZ Company Ltd."
                        value={formData.businessName}
                        onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                      />
                      {formErrors.businessName && <div className="text-danger">{formErrors.businessName}</div>}
                    </Form.Group>

                    <Form.Group className={"form-group"}>
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        placeholder="www.example.com"
                        value={formData.website}
                        onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                      />
                      {formErrors.website && <div className="text-danger">{formErrors.website}</div>}
                    </Form.Group>

                    <Form.Group className={"form-group"}>
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        as="select"
                        name="industry"
                        value={formData.industry}
                        onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
                      >
                        <option value="" className="empty">Select</option>
                        <option value="Advt. and marketing">Advertising & Marketing</option>
                        <option value="Education or E-learning">Education/E-Learning</option>
                        <option value="Financial Services">Financial Services/Banking</option>
                        <option value="Food Industry">Food/FoodTech</option>
                        <option value="Health Care">Healthcare</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Insurance or Trading">Insurance/Trading</option>
                        <option value="Legal Services">Lawyers/Legal Services</option>
                        <option value="Logistic">Logistic</option>
                        <option value="Print Media">Print Media</option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Religious Organisation">Religious Organisation</option>
                        <option value="Retail">Retail</option>
                        <option value="Wholesale">Wholesale</option>

                        <option value="Real Estate">Other</option>
                      </Form.Control>
                      {formErrors.industry && <div className="text-danger">{formErrors.industry}</div>}
                    </Form.Group>

                    <Form.Group className={"form-group"}>
                      <Form.Label>Contact Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        placeholder="John Doe"
                        value={formData.fullName}
                        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                      />
                      {formErrors.fullName && <div className="text-danger">{formErrors.fullName}</div>}
                    </Form.Group>

                    <Form.Group className={"form-group"}>
                      <Form.Label>Contact Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactEmail"
                        placeholder="mail@website.com"
                        value={formData.contactEmail}
                        onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })}
                      />
                      {formErrors.contactEmail && <div className="text-danger">{formErrors.contactEmail}</div>}
                    </Form.Group>

                    <Form.Group className={"form-group"}>
                      <Form.Label>Contact Mobile</Form.Label>
                      <Form.Control
                        type="text"
                        name="contactMobile"
                        placeholder="+17773334444"
                        value={formData.contactMobile}
                        onChange={(e) => setFormData({ ...formData, contactMobile: e.target.value })}
                      />
                      {formErrors.contactMobile && <div className="text-danger">{formErrors.contactMobile}</div>}
                    </Form.Group>

                    <Form.Group className={"form-group"}>
                      <Form.Label>Project Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="projectType"
                        value={formData.projectType}
                        onChange={(e) => setFormData({ ...formData, projectType: e.target.value })}
                      >
                        <option value="">Select</option>
                        <option value="on-going">On-going</option>
                        <option value="one-time">One-time</option>
                        <option value="Adhoc">Adhoc</option>
                        {/* <option value="Website">Website</option>
                            <option value="Custom App">Custom App</option>
                            <option value="CRM">Customer Relationship Manager</option>
                            <option value="ERP">Enterprise Resource Planning</option>
                            <option value="HRM">Human Resource Management</option>
                            <option value="PMIS">Project Management</option>
                            <option value="SaaS">Software as Service</option>
                            <option value="CMS">Content Management System</option>
                            <option value="Other">Other</option>*/}
                      </Form.Control>
                      {formErrors.projectType && <div className="text-danger">{formErrors.projectType}</div>}
                    </Form.Group>

                    {(formData.projectType === 'on-going' || formData.projectType === 'one-time') && (

                      <Form.Group className={"form-group"}>
                        <Form.Label>Project Duration</Form.Label>
                        <Form.Control
                          as="select"
                          name="ongoingOptions"
                          value={formData.ongoingOptions}
                          onChange={(e) => setFormData({ ...formData, ongoingOptions: e.target.value })}
                        >
                          <option value="">Select</option>
                          <option value="less-than-a-month">Less than a month</option>
                          <option value="less-than-3-months">Less than 3 months</option>
                          <option value="less-than-6-months">Less than 6 months</option>
                          <option value="more-than-6-months">More than 6 months</option>
                        </Form.Control>
                        {formErrors.ongoingOptions && <div className="text-danger">{formErrors.ongoingOptions}</div>}
                      </Form.Group>

                    )}
                    {/* <Col md={12}>
                        <Form.Group className={"form-group"}>
                          <Form.Label>Attach Project File</Form.Label>
                          <Form.Control type="file" name="projectFile" onChange={handleFileChange} />
                          {formErrors.projectFile && <div className="text-danger">{formErrors.projectFile}</div>}
                        </Form.Group>
                      </Col> */}

                    <Form.Group className={"form-group"}>
                      <Form.Label>Project Description</Form.Label>
                      <ReactQuill placeholder="Please desribe breifly about your business and project interests." value={formData.projectDescription} onChange={handleEditorChange} />
                      {formErrors.projectDescription && (
                        <div className="text-danger">{formErrors.projectDescription}</div>
                      )}
                    </Form.Group>
                    <Form.Group className={"form-group text-center"}>
                      <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>Submit</Button> &nbsp;
                      {submissionError && (
                        <div className="text-danger">{submissionError}</div>
                      )}
                    </Form.Group>

                  </Form>
                  <div id="thankYouMessage" className={"text-center thank-you-message"}>
                      <h3><strong>Thank you for choosing us!</strong></h3>
                      <p></p>
                      <p>We're delighted to have you on our list of prestigious clients. Our experts will reach out to you within the next 24 hours. Welcome aboard!</p>
                  </div>
                  {showLoader ? (
                    <AnimatedCircles  /> 
                  ) : ""}                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <TrustedCustomers />
      <div className={"get-started-process"}>
        <ProjectProcess />
      </div>
    </>
  );
};

export default getStarted;