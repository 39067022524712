// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from "./assets/styles.css";
import processImage from "./assets/images/process.png"

//images

function contactProcess(props) {    
    const { data } =  props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        // Update the document title using the browser API        
    }); 
 
    return (
        <section className="main-section contactProcess">
            <Container>
                <Row>
                    <Col md={5}>
                        <h2>
                            We efficiently deliver<br />
                            <span>Innovative Solutions.</span>
                        </h2>                        
                        <p>Efficiency doesn't mean cutting corners; it means eliminating waste, optimizing resources, and delivering exceptional value. We prioritize quality at every stage, from ideation to testing and refinement.</p><p>Our goal is to help you get your product to market faster, allowing you to stay ahead of the competition and respond swiftly to changing customer demands.</p>
                    </Col> 
                </Row>
            </Container>
        </section>
    );
}
export default contactProcess;
