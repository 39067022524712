import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Styles from "./assets/styles.css";

const Collabs = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col className={"collabs d-flex justify-content-center align-items-center"}>
                        {/* <span className={"sec-title"}>Recognitions: </span> */}
                        {/* <img alt="Clutch" src="https://www.appfutura.com/img/badges/badge-top-app-company-chandigarh.png" /> */}
                        <a target="_blank" href="https://clutch.co/profile/jasthink-softwares"><img alt="Clutch" src="https://jasthinkassets.s3.amazonaws.com/collab/top_clutch.co_app_development_company_startup_india.png" /></a>
                        {/* <a target="_blank" href="https://clutch.co/profile/jasthink-softwares"><img alt="Clutch" src="https://jasthinkassets.s3.amazonaws.com/collab/top_clutch.co_software_developers_startup_india.png" /></a> */}
                        <a target="_blank" href="https://www.designrush.com/agency/profile/jasthink-softwares"><img alt="Design Rush" alt="Listed as Best App Development Companies on DesignRush" src="https://jasthinkassets.s3.amazonaws.com/collab/Mobile-App-Development.png" /></a>
                        <a target="_blank" href="https://www.g2.com/users/jasthink-softwares"><img alt="G2" src="https://jasthinkassets.s3.amazonaws.com/collab/g2-logo.svg" /></a>
                        <a target="_blank" href="https://www.goodfirms.co/company/jasthink-softwares-pvt-ltd"><img alt="GoodFirms"  src="https://jasthinkassets.s3.amazonaws.com/collab/good-firms.svg" /></a>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default Collabs;