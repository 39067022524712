// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from "react-helmet"; 

import ServiceLinks from '../servicesPage/serviceLinks';
import FillerBox from '../../components/fillerBox';
import ContactForm from '../../components/contactForm'
import ProjectProcess from "../../components/projectProcess";
// images 

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function SingleService() {
    const [slug, setSlug] = useState('');
    const [metaData, setData] = useState(null);
    const [techStack, setStack] = useState(null);
     
    useEffect(() => {
        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const filteredParts = parts.filter((part) => part !== '');
        const lastPart = filteredParts[filteredParts.length - 1];
        setSlug(lastPart);
        //setData(content[slug]);
        //console.log('l')
        //setStack(content[slug].techStack);        
    });

    let content = [];
    content["cloud-computing"] = {
        color: "#A74F9F",
        subTitle: 'Unlock Exponential Growth',
        title: 'A Tailored Cloud Strategy',
        desc: "Every organization possesses a unique purpose and pursues a distinct growth trajectory. To capitalize on cutting-edge technologies, achieve rapid scaling, and capture new market opportunities, a customized cloud strategy is essential.",
        footNote: ["#CloudEnterprise", "#ScaleUpStrategy", "#DigitalTransformation"],
        metaTitle: "Future-Proof Your Business: Cloud Infrastructure & Data Management",
        metaDesc: "Scale your business with Jasthink's cloud services. Tailored solutions for all your infrastructure & data needs.",
        metakeywords: "reduce IT infrastructure costs with cloud solutions, automate business processes with cloud-based tools, improve collaboration and communication with cloud apps, boost business agility with scalable cloud infrastructure, how to prevent data breaches in the cloud, secure cloud storage options for sensitive data, cloud-based project management tools for construction companies, cloud-based project management tools for finance companies, cloud-based project management tools for brokers, enhance customer relationship management (CRM) with cloud applications, cloud migration strategy for legacy applications,  hybrid cloud migration solutions for businesses, minimize downtime during cloud migration,  cost-effective cloud migration services",
        prospectSection:
        {
            title: "Jasthink Softwares - Your Trusted AWS Partner",
            subTitle: "EMBRACE AGILITY IN THE CLOUD",
            desc: "In today's dynamic digital world, businesses require agility, innovation, and cost-effectiveness to thrive. Jasthink Softwares empowers your organization by harnessing the power of Amazon Web Services (AWS), the world's leading cloud computing platform."
        },
        whyChooseSection:
        {
            service: "Why choose Jasthink Softwares for AWS Solutions?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/why-jasthink-point/point1.svg",
                    content: "<strong>Forerunners in Cloud Expertise:</strong><br />Since 2015, we've been at the forefront of AWS innovation, providing comprehensive cloud solutions as a trusted Standard Consulting Partner.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/why-jasthink-point/point2.svg",
                    content: "<strong>Certified Professionals at Your Service:</strong><br />Our team of certified AWS developers delivers exceptional infrastructure design and execution, meticulously tailored to your specific requirements.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/why-jasthink-point/point3.svg",
                    content: "<strong>Scalability for Every Journey:</strong><br />We support businesses of all sizes, from nimble startups seeking agility to established enterprises requiring robust cloud solutions.",
                }
            ]
        },
        benefitsSection:
        {
            service: "What are the benefits of our AWS Approach?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/1.svg",
                    content: "<strong>Agile Development at its Core:</strong><br />We collaborate closely with you to implement cloud solutions swiftly and efficiently, fostering rapid iteration and groundbreaking innovation.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/2.svg",
                    content: "<strong>Cost-Optimization Experts:</strong><br />Our deep understanding of AWS helps you leverage its cost-effectiveness, minimizing your IT infrastructure expenses.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/3.svg",
                    content: "<strong>Enhanced Performance, Unmatched Security:</strong><br />AWS offers unparalleled scalability, security, and reliability to ensure your applications run seamlessly and your data remains secure.",
                }
            ]
        },
        serviceTechSection:
        {
            title: "AWS Technologies",
            subTitle: "SERVICES WE PROVIDE",
        },
        techStack: [
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-ec2.svg",
                title: "AWS<br />Elastic Compute",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-lambda.svg",
                title: "AWS<br />Lambda",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-rds.svg",
                title: "AWS<br />RDS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-s3.svg",
                title: "AWS<br />S3",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-route53.svg",
                title: "AWS<br />Route 53",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-elastic.svg",
                title: "AWS<br />Elastic Compute",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-dynmoDb.svg",
                title: "AWS<br />DynamoDB",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-cloud-front.svg",
                title: "AWS<br />Cloud Front",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-cloud-formation.svg",
                title: "AWS<br />Cloud Formation",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-api-gateway.svg",
                title: "AWS<br />Cloud Front",
                content: "",
            }
        ],
        formSection:
        {
            title: "the Cloud Compute",
            content: "Jasthink Softwares is your one-stop shop for all your AWS needs. We take the time to understand your unique business goals and craft a customized cloud strategy that fuels growth and innovation for your organization.",
            formTitle: "Schedule Your Free AWS Consultation",
            formSubTitle: "Ready to unlock the power of the cloud and transform your business?"
        },
    };
    content["it-consultancy"] = {
        color: "#1E7E6C",
        subTitle: 'Expert IT Consulting',
        title: 'Unify Your Purpose with Strategy',
        desc: "Today’s business landscape calls for new enterprise strategies, technological innovation, and a holistic approach to the changing expectations of customers and employees. To thrive amidst change, you need a transformative business strategy centered on purpose.",
        footNote: ["#ITConsulting", "#Transformative", "#StrategicAlignment"],
        metaTitle: "Expert IT Consulting Services for Business Transformation | Jasthink Softwares",
        metaDesc: "Unlock the full potential of your business with our IT consulting services. Our expert consultants offer strategic guidance, technology solutions, and industry expertise to drive innovation and growth. Partner with Jasthink Softwares for tailored IT consulting services that align with your business goals.",
        metakeywords: "cloud migration consulting, technology consulting, Business Strategy & Transformation, technology solutions, IT advisory, IT planning, technology optimization, IT governance",
        prospectSection:
        {
            title: "Unleash Your Business Potential with Expert IT Consultancy Services",
            subTitle: "EMPOWER YOUR GROWTH",
            desc: "In today's digital world, technology is the backbone of most business operations. It empowers businesses to streamline processes, enhance communication, improve collaboration, and ultimately achieve their strategic goals. However, navigating the ever-evolving IT landscape can be complex and time-consuming. This is where Jasthink Softwares comes in. We provide comprehensive IT consultancy services designed to empower your organization and unlock the full potential of technology."
        },
        whyChooseSection:
        {
            service: "Why choose us for IT Consultancy Services?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/strategy.svg",
                    content: "<strong>IT Strategy & Planning:</strong><br />We collaborate with you to define a clear IT roadmap aligned with your overall business goals, ensuring your technology investments support your long-term growth.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/analyse.svg",
                    content: "<strong>Needs Assessment & Analysis:</strong><br />Our experienced consultants conduct a thorough assessment of your current IT infrastructure and identify areas for improvement, optimization, and cost reduction.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/system-cloud.svg",
                    content: "<strong>Cloud Solutions:</strong><br /> We help you leverage the power of cloud computing (e.g., AWS, Azure, GCP) to enhance scalability, agility, and security while reducing operational costs.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/compliance-document.svg",
                    content: "<strong>Cybersecurity Solutions:</strong><br />We design and implement robust cybersecurity measures to protect your critical data assets from evolving cyber threats, including malware, ransomware, and phishing attacks.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/model-cube.svg",
                    content: "<strong>Network & System Management:</strong><br />We proactively monitor and maintain network and systems for optimal performance, addressing potential issues and implementing preventative measures to minimize downtime.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/plan.svg",
                    content: "<strong>IT Infrastructure Optimization:</strong><br />We analyze your existing IT infrastructure and propose solutions to optimize performance, improve resource utilization, and lower overall costs.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/lead-management.svg",
                    content: "<strong>Managed IT Services:</strong><br />We offer comprehensive managed IT services to handle your day-to-day IT operations, freeing your internal staff to focus on core business activities.",
                },
            ]
        },
        benefitsSection:
        {
            service: "Benefits of Partnering with Jasthink Softwares.",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/1.svg",
                    content: "<strong>Boost Efficiency & Productivity:</strong><br />Our solutions streamline processes, improve data access, and empower your workforce to achieve greater results in less time.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/2.svg",
                    content: "<strong>Enhanced Security & Compliance:</strong><br />We prioritize robust security measures to safeguard your data and ensure strict regulatory compliance.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/3.svg",
                    content: "<strong>Reduced IT Expenses:</strong><br />Our optimization strategies help you control IT costs by identifying savings opportunities, recommending cost-effective solutions, and leveraging cloud technologies for maximum cost efficiency.",
                }
            ]
        },
        serviceTechSection:
        {
            title: "A Deep Dive into the <br />Languages and Frameworks",
            subTitle: "UNVEILING CODE BEHIND",
        },
        techStack: [
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/html.svg",
                title: "HTML/CSS &<br />Frameworks",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/react.svg",
                title: "ReactJS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/angular.svg",
                title: "AngularJS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/vue.svg",
                title: "VUE",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/php.svg",
                title: "PHP & Frameworks",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/node.svg",
                title: "NodeJS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dotnet.svg",
                title: "C#.NET",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/python.svg",
                title: "Python",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/react-native.svg",
                title: "React Native",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/flutter.svg",
                title: "Flutter",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/xamarin.svg",
                title: "Xamarin",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mysql.svg",
                title: "MySQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/sql.svg",
                title: "Microsoft SQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mongoDB.svg",
                title: "MongoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dynamoDB.svg",
                title: "DynamoDB",
                content: "",
            }
        ],
        formSection:
        {
            title: "the IT Consultancy",
            content: "From understanding your unique business objectives to crafting a customized IT strategy, Jasthink Softwares is your one-stop shop for all your IT consultancy needs. We empower your organization to leverage technology strategically, driving growth, innovation, and a competitive edge. ",
            formTitle: "Schedule Your Free Consultation",
            formSubTitle: "Ready to transform your IT infrastructure and propel your business forward?"
        },
    };
    content["enterprise-solution"] = {
        color: "#2395BE",
        subTitle: 'Unlock Enterprise Growth',
        title: 'Tailored Solutions for Success',
        desc: "Thrive in today's digital landscape. Our comprehensive enterprise solutions deliver a holistic approach with intelligent technology. We leverage diverse partnerships to maximize your competitive advantage.",
        footNote: ["#ScaleWithTech", "#FutureProof", "#BizPartnerships"],
        metaTitle: "Empower Your Enterprise with Tailored Solutions | Jasthink Softwares",
        metaDesc: "Elevate your enterprise with our comprehensive solutions tailored to your unique needs. Explore our range of enterprise services including software development, IT infrastructure, security solutions, and more. Partner with Jasthink Softwares for innovative solutions that drive efficiency and growth.",
        metakeywords: "increased operational efficiency, enhanced security, improved customer experience, Enterprise solutions, enterprise services, business solutions,IT infrastructure, security solutions, Software Development Solutions, business efficiency, enterprise growth",
        prospectSection:
        {
            title: "Empower your business growth with enterprise solutions",
            subTitle: "STREAMLINE OPERATIONS AND DRIVE RESULTS",
            desc: "<p>In today's competitive business landscape, streamlining operations, maximizing efficiency, and gaining a data-driven edge are crucial for success. At Jasthink Softwares, we provide a comprehensive suite of enterprise solutions designed to empower your organization and unlock its full potential. Our solutions address common pain points and unlock your full potential, enabling you to:</p><p><strong>Streamline operations &mdash;</strong> Eliminate inefficiencies, automate workflows, and boost team productivity.</p><p><strong>Gain valuable insights &mdash;</strong> Leverage real-time data to make informed decisions, identify growth opportunities, and gain a competitive edge.</p><p><strong>Drive tangible results &mdash;</strong> Enhance customer satisfaction, increase revenue, and achieve your strategic business goals.</p>"
        },
        whyChooseSection:
        {
            service: "Why Choose Jasthink Softwares for Your Enterprise Solutions?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/enterprise-solutions/point1.svg",
                    content: "<strong>Deep Industry Expertise:</strong><br />Our team possesses in-depth knowledge of various industries and the specific challenges faced by enterprise businesses. We leverage this expertise to tailor our solutions to your unique needs.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/enterprise-solutions/point2.svg",
                    content: "<strong>Proven Track Record of Success:</strong><br />We have a history of helping enterprises achieve significant results with our solutions. Our client portfolio showcases success stories across diverse industries, demonstrating our capabilities.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/enterprise-solutions/point3.svg",
                    content: "<strong>Scalable and Flexible Solutions:</strong><br />Our enterprise solutions are designed to scale and adapt to your evolving business needs. We offer customization options to ensure a perfect fit for your current and future requirements.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/enterprise-solutions/point4.svg",
                    content: "<strong>Focus on Security and Compliance:</strong><br />We prioritize the security of your data and ensure our solutions adhere to industry standards and regulations. You can trust Jasthink Softwares to keep your data safe and secure.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/enterprise-solutions/point5.svg",
                    content: "<strong>Dedicated Customer Support:</strong><br />Our dedicated support team is available 24/7 to provide ongoing assistance and ensure a smooth user experience. We are committed to your success and offer comprehensive support.",
                }
            ]
        },
        benefitsSection:
        {
            service: "Here are some of the benefits of partnering with Jasthink Softwares.",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/1.svg",
                    content: "<strong>Proven Expertise:</strong><br />Leverage our extensive industry experience and proven track record of success. We have a deep understanding of the challenges faced by enterprises in various sectors, and we've helped numerous organizations achieve significant results with our solutions. By partnering with Jasthink Softwares, you gain access to a team of experts who can tailor our solutions to your specific needs and ensure maximum impact on your business goals.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/2.svg",
                    content: "<strong>Scalable Solutions:</strong><br />As your business grows and evolves, your technology needs will change as well. Our enterprise solutions are designed to scale with you, ensuring they remain valuable assets for years to come. We offer flexible architecture and deployment options to accommodate your current and future requirements. Whether you're a large multinational corporation or a growing mid-sized business, our solutions can adapt to your specific size and structure.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/3.svg",
                    content: "<strong>Customization Options:</strong><br />We understand that a one-size-fits-all approach doesn't work for enterprise software. That's why we offer a wide range of customization options to tailor our solutions to your unique workflows and business requirements. Our team can work with you to configure our solutions to seamlessly integrate with your existing systems and processes. Additionally, we can develop custom features and functionality to address your specific needs and challenges.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/4.svg",
                    content: "<strong>Dedicated Support:</strong><br />Our commitment to your success goes beyond the initial implementation of our enterprise solutions. We offer dedicated customer support to ensure a smooth user experience throughout your journey with our software. Our highly trained support team is available 24/7 to provide ongoing assistance, answer your questions, and troubleshoot any issues that may arise. You can rely on us to be there for you every step of the way.",
                }
            ]
        },
        serviceTechSection:
        {
            title: "Optimizing Your Business while<br /> enabling efficiency, security, and growth",
            subTitle: "ENGINEERED FOR PERFORMANCE",
        },
        techStack: [
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/html.svg",
                title: "HTML/CSS &<br />Frameworks",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/react.svg",
                title: "ReactJS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/angular.svg",
                title: "AngularJS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/vue.svg",
                title: "VUE",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/php.svg",
                title: "PHP & Frameworks",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/node.svg",
                title: "NodeJS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dotnet.svg",
                title: "C#.NET",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/python.svg",
                title: "Python",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/react-native.svg",
                title: "React Native",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/flutter.svg",
                title: "Flutter",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/xamarin.svg",
                title: "Xamarin",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mysql.svg",
                title: "MySQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/sql.svg",
                title: "Microsoft SQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mongoDB.svg",
                title: "MongoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dynamoDB.svg",
                title: "DynamoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-ec2.svg",
                title: "AWS<br />Elastic Compute",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-lambda.svg",
                title: "AWS<br />Lambda",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-rds.svg",
                title: "AWS<br />RDS",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-s3.svg",
                title: "AWS<br />S3",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-route53.svg",
                title: "AWS<br />Route 53",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-elastic.svg",
                title: "AWS<br />Elastic Compute",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-dynmoDb.svg",
                title: "AWS<br />DynamoDB",
                content: "",
            },
            ,
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-cloud-front.svg",
                title: "AWS<br />Cloud Front",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-cloud-formation.svg",
                title: "AWS<br />Cloud Formation",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-api-gateway.svg",
                title: "AWS<br />Cloud Front",
                content: "",
            }
        ],
        formSection:
        {
            title: "Enterprise Solutions",
            content: "Discuss your specific needs with our enterprise solution experts and discover how we can help you achieve your business goals. See how we've helped other enterprises like yours achieve remarkable results.",
            formTitle: "Schedule Free Consultation!",
            formSubTitle: "Discuss your business challenges and explore tailored solutions."
        },
    };
    content["ux-cx-design"] = {
        color: "#CE9154",
        subTitle: 'Design for Delight',
        title: 'Optimize User & Customer Experience',
        desc: "Uncover customer insights to optimize your brand experience. We leverage Voice of Customer (VoC) programs and data analysis to identify key metrics aligned with your business goals. Partner with us to create user journeys that delight!",
        footNote: ["#DesignForImpact", "#InclusiveDesign", "#MicrointeractionsMatter"],
        metaTitle: "Elevate User Experience with Expert UX/CX Design | Jasthink Softwares",
        metaDesc: "Transform your digital presence with our expert UX/CX design services. We create user journeys that engage and delight, leading to a seamless customer experience. Partner with Jasthink Softwares for tailored UX/CX design that drives business success.",
        metakeywords: "User experience (UX) design, customer experience (CX) design, user interface (UI) design, user journey, customer journey mapping, usability testing, customer satisfaction, user engagement, conversion rate optimization, How to improve user onboarding experience with UX design, Increase customer retention through effective CX design strategies",
        prospectSection:
        {
            title: "Craft User-Centric Experiences that Drive Engagement and Growth",
            subTitle: "DESIGN BEYOND AESTHETICS",
            desc: "In today's competitive digital landscape, captivating users and building long-term customer loyalty requires more than just an attractive website or app. Exceptional user experience (UX), customer experience (CX), and user interface (UI) design are essential ingredients for success. At Jasthink Softwares, we help businesses unlock the combined power of these design disciplines to create experiences that not only look beautiful but also function flawlessly and drive tangible results."
        },
        whyChooseSection:
        {
            service: "Why Jasthink Softwares is Your UI/UX/CX Design Powerhouse?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/ux-ui/point1.svg",
                    content: "<strong>User-Centric Design & Proven Results:</strong><br />We're obsessed with understanding your users.  Through deep research (interviews, usability testing) and a proven track record (mention specific results like increased conversion rates or client names), we craft UI/UX/CX experiences that captivate users and drive business growth.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/ux-ui/point2.svg",
                    content: "<strong>Holistic Approach & Seamless Experience:</strong><br />Forget juggling multiple vendors. Our comprehensive suite of UX, CX, and UI design services ensures a unified user journey across all touchpoints. One team, one vision – a flawless user experience that resonates.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/ux-ui/point3.svg",
                    content: "<strong>Data-Driven Decisions & Measurable Impact:</strong><br />Beautiful design is just the beginning. We leverage data analytics to track key metrics (conversion rates, user engagement) and demonstrate the ROI of your design investment. Your success is our success – we focus on measurable results.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/ux-ui/point4.svg",
                    content: "<strong>Long-Term Partnership & Continuous Innovation:</strong><br />Our commitment goes beyond the initial project. We offer ongoing support to ensure your designs evolve alongside user needs. Plus, our team stays ahead of the curve, incorporating cutting-edge design trends and technologies into your solutions.",
                }
                ,
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/ux-ui/point5.svg",
                    content: "<strong>Your Trusted Partner for Design Success:</strong><br />We're passionate about creating impactful design solutions that elevate your brand.  Choose Jasthink Softwares and experience the difference of a partner dedicated to your UI/UX/CX success.",
                }
            ]
        },
        benefitsSection:
        {
            service: "What are the Benefits of a Holistic Design Approach?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/1.svg",
                    content: "<strong>Enhanced User Engagement:</strong><br />Improve user satisfaction and keep them coming back for more with intuitive and enjoyable interactions.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/2.svg",
                    content: "<strong>Increased Conversion Rates:</strong><br />Optimize user journeys to streamline conversions, leading to more sales, sign-ups, or desired actions.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/3.svg",
                    content: "<strong>Boosted Brand Reputation:</strong><br />Create positive brand experiences that foster trust and loyalty, leading to positive word-of-mouth marketing.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/4.svg",
                    content: "<strong>Measurable ROI:</strong><br />Track the impact of your design efforts through data and analytics to demonstrate the return on investment in UX, CX, and UI design.",
                }
            ]
        },
        serviceTechSection:
        {
            title: "",
            subTitle: "",
        },
        techStack: [],
        formSection:
        {
            title: "Crafting Exceptional User Experiences",
            content: "Our team of experienced UI/UX designers is passionate about creating user-centric experiences that drive engagement, growth, and success for your business.  We offer a range of services tailored to your specific needs, from initial concept development and prototyping to user testing and implementation.",
            formTitle: "Start your Free Website Audit",
            formSubTitle: "Ready to identify areas for improvement in your existing design."
        },
    };
    content["mobile-app-development"] = {
        color: "#CE54C5",
        subTitle: 'Revised: Build Captivating Apps',
        title: 'Expert Mobile App Development',
        desc: "Over 15 years of experience crafting mobile apps that captivate users. We guide you through every step, from concept to launch, with strategic user journey design and audience engagement strategies. (Partner with Jasthink Softwares for tailored mobile app development that meets your business needs.)**",
        footNote: ["#CustomApps", "#MobileApps", "#AppInnovation"],
        metaTitle: "Crafting Innovative Mobile Apps for Your Business | Jasthink Softwares",
        metaDesc: "Elevate your business with our expert mobile app development services. From concept to launch, our team delivers custom mobile solutions that drive engagement and growth. Partner with Jasthink Softwares for tailored mobile app development that meets your business needs.",
        metakeywords: "mobile app development, app development services, iOS app development, Android app development, cross-platform app development, mobile app design, mobile UX design, mobile app strategy, mobile app deployment, mobile app maintenance, mobile app optimization, user engagement, how to choose the right mobile app development platform (iOS, Android, or cross-platform), increase customer loyalty with a mobile app rewards program",
        prospectSection:
        {
            title: "Empower Your Business with Exceptional App Development",
            subTitle: "UNLEASH THE POWER OF MOBILE",
            desc: "In today's mobile-first world, a well-designed and engaging mobile app is no longer a luxury, it's a necessity. At Jasthink Softwares, we are passionate about creating exceptional mobile app experiences that not only look stunning but also deliver tangible results for your business."
        },
        whyChooseSection:
        {
            service: "How we can help you build the perfect mobile app?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/strategy.svg",
                    content: "<strong>Discovery & Strategy:</strong><br />We take the time to understand your business goals, target audience, and unique needs. Through workshops, user research, and competitor analysis, we collaboratively define the app's concept, features, and target user personas.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/ux.svg",
                    content: "<strong>User-Centered Design:</strong><br />Our UX/UI design team creates intuitive and visually appealing interfaces that prioritize user experience (UX) at every touchpoint. We conduct usability testing to ensure a smooth and enjoyable user journey within your app.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/agile.svg",
                    content: "<strong>Agile Development:</strong><br />We leverage agile development methodologies to deliver your app in iterations, allowing for continuous feedback and adjustments throughout the development process. This ensures the final product aligns perfectly with your vision and user needs.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/backend-api.svg",
                    content: "<strong>Robust Backend Development:</strong><br />Our experienced backend developers build secure and scalable backend infrastructure to power your app's functionalities. We integrate with essential APIs and services to ensure seamless data management and functionality.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/quiz-alt.svg",
                    content: "<strong>Quality Assurance & Testing:</strong><br />Our rigorous QA process ensures your app is thoroughly tested across different devices and platforms. We identify and fix any bugs or performance issues before launch, guaranteeing a high-quality user experience.",
                }
            ]
        },
        benefitsSection:
        {
            service: "Pros and Cons of different mobile app development practices:",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/1.svg",
                    content: "<p><strong>Native App Development</strong> offers the best possible performance and user experience by leveraging device-specific features and functionalities (e.g., camera, GPS, accelerometers). It delivers smoother animations and graphics compared to cross-platform apps, making it ideal for complex apps that require high performance, tight integration with device hardware (e.g., fitness trackers), or offline functionality.</p><br /><p>However, native app development requires separate codebases for Android and iOS, leading to potentially longer development times and higher costs. Additionally, updates need to be submitted and approved by the respective app stores (Google Play Store and Apple App Store), which can introduce a delay.</p>",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/2.svg",
                    content: "<p><strong>Cross-Platform App Development</strong> provides a faster and more cost-effective solution as you can build the app once and deploy it across multiple platforms (Android and iOS). It's easier and faster to implement updates as you only need to update a single codebase. This approach is suitable for apps with a moderate level of complexity that prioritize reach and faster time-to-market (e.g., social media apps, e-commerce apps).</p><br /><p>However, cross-platform app development may have limitations in leveraging some device-specific features and functionalities compared to native apps. Performance might not be quite as smooth as native apps, especially for graphically intensive applications.</p>",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/3.svg",
                    content: "<p><strong>Hybrid App Development</strong> strikes a balance between native and cross-platform development, offering a web-like experience within a native app container. It leverages existing web development skills and codebase, potentially reducing development time and costs. This can be a good option for simpler apps or those with a large existing web codebase that can be leveraged (e.g., informational apps, content portals).</p><br /><p>However, hybrid app development can feel less native compared to native apps, potentially impacting user experience. It may also have limitations in accessing some device functionalities compared to native apps.</p><br /><p><strong>Ultimately, the best mobile app development approach depends on your specific project requirements, budget, and desired functionalities.</strong></p>",
                }
            ]
        },
        serviceTechSection:
        {
            title: "Powering Innovation,<br />Our Mobile App Tech Stack",
            subTitle: "BUILT FOR PERFORMANCE",
        },
        techStack: [
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/swift.png",
                title: "Swift",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/java.png",
                title: "Java",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/mobile-app-dev/kotlin.png",
                title: "Kotlin",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/react-native.svg",
                title: "React Native",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/flutter.svg",
                title: "Flutter",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/xamarin.svg",
                title: "Xamarin",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/php.svg",
                title: "PHP",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/node.svg",
                title: "Node",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-lambda.svg",
                title: "AWS<br />Lambda",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dotnet.svg",
                title: "C#.NET",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mysql.svg",
                title: "MySQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mongoDB.svg",
                title: "MongoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dynamoDB.svg",
                title: "AWS<br />DynamoDB",
                content: "",
            },
        ],
        formSection:
        {
            title: "developing your custom Mobile Applications",
            content: "Our team of experienced mobile app developers is here to partner with you from concept to launch and beyond. We'll work closely with you to understand your specific needs, target audience, and business goals.  With a focus on user-centric design, advanced development expertise, and a data-driven approach, we'll create a mobile app that delivers exceptional user experiences and drives measurable results.",
            formTitle: "Schedule Your Free Consultation",
            formSubTitle: "Tell us about your project and receive a tailored app development plan."
        },
    };

    content["web-app-development"] = {
        color: "#675866",
        subTitle: 'Power your Business',
        title: 'Custom Web App Development',
        desc: "For over 15 years, we've helped businesses thrive with custom web apps. We transform your ideas into powerful solutions that drive engagement and innovation. Partner with Jasthink Softwares for tailored web app development that scales with you.",
        footNote: ["#ProgressiveApps", "#CloudNativeDev", "#NextGenApps"],
        metaTitle: "Transform Your Ideas into Powerful Web Apps | Jasthink Softwares",
        metaDesc: "Unlock the potential of your business with our expert web app development services. From concept to launch, our team delivers custom web solutions that drive engagement and innovation. Partner with Jasthink Softwares for tailored web app development that meets your business needs.",
        metakeywords: "web app development, web application development, custom web apps, web development services, full-stack development, front-end development, back-end development, responsive web design, web application integration (API), web security, progressive web apps (PWAs), single-page applications (SPAs), e-commerce development, enterprise web application development, how to choose the right web development framework (e.g., React, Angular), best practices for web application security, how to improve web application performance, cost of developing a custom web application, how to migrate a legacy web application to the cloud, benefits of using a progressive web app (PWA), how to create a single-page application (SPA), e-commerce web development solutions",
        prospectSection:
        {
            title: "Jasthink Softwares - Expert Web Development Solutions for Your Business",
            subTitle: "BUILDING WEBSITES THAT THRIVE",
            desc: "In today's digital age, your website is more than just a digital storefront or information hub &mdash; it's the heart of your online presence. It's where you build trust with potential customers, showcase your brand, and convert visitors into leads or sales. <br /><br />At Jasthink Softwares, we understand the critical role your website plays in your business success. That's why we specialize in creating exceptional web development solutions that are not only visually stunning and functionally sound, but also built with trust, on-time delivery, data protection, and ongoing support at the forefront of our approach. We believe in building trust with our clients through open communication, transparency throughout the development process, and a genuine commitment to understanding your unique business needs and goals."
        },
        whyChooseSection:
        {
            service: "How we can help you build the perfect website?",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/web-app-development/discover.svg",
                    content: "<strong>Discovery & Planning:</strong><br />We take the time to understand your business goals, target audience, and unique needs. Through collaborative workshops and competitor analysis, we define your website's purpose, functionalities, and ideal user experience.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/web-app-development/customization.svg",
                    content: "<strong>Custom Web Design:</strong><br />Our experienced designers create visually compelling and user-friendly interfaces that resonate with your brand identity and target audience. We prioritize responsive design to ensure your website looks fantastic and functions flawlessly across all devices (desktop, mobile, tablets).",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/web-app-development/skilled-developers.svg",
                    content: "<strong>Seamless Development:</strong><br /> Our skilled web developers leverage the latest technologies and frameworks to build secure, scalable, and high-performing websites. We integrate with essential APIs and services to enhance functionality and user experience.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/web-app-development/cms.svg",
                    content: "<strong>Content Management System (CMS):</strong><br />We empower you with a user-friendly CMS that allows you to easily update and manage your website content without needing any coding knowledge.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/services-assets/web-app-development/seo.svg",
                    content: "<strong>Search Engine Optimization (SEO):</strong><br />We integrate SEO best practices throughout the web development process to improve your website's search engine ranking and organic visibility. This drives qualified traffic to your site and increases your chance of converting visitors into leads or customers.",
                }
            ]
        },
        benefitsSection:
        {
            service: "Benefits of Our Web Development Services:",
            points: [
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/1.svg",
                    content: "<strong>Enhanced User Experience:</strong><br />We create websites that are intuitive, engaging, and deliver a positive user experience for your visitors, boosting brand loyalty and conversions.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/2.svg",
                    content: "<strong>Improved Search Engine Ranking:</strong><br />Our SEO-focused approach helps your website rank higher in search engine results pages (SERPs), attracting more qualified traffic organically.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/3.svg",
                    content: "<strong>Increased Website Traffic: </strong><br />Effective web design and SEO strategies drive more visitors to your website, creating greater opportunities for lead generation and sales.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/4.svg",
                    content: "<strong>Scalability and Security:</strong><br />We build websites with scalability in mind, ensuring they can adapt and grow alongside your business needs. We prioritize secure development practices to protect your website and user data.",
                },
                {
                    iconImg: "https://jasthinkassets.s3.amazonaws.com/5.svg",
                    content: "<strong>Easy Content Management:</strong><br />User-friendly CMS empowers you to maintain complete control over your website content, enabling timely updates and flexibility.",
                }
            ]
        },
        serviceTechSection:
        {
            title: "Building Web-Applications<br />for Speed and Security",
            subTitle: "MODERN & SCALABLE",
        },
        techStack: [
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/html.svg",
                title: "HTML/CSS &<br />Frameworks",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/react.svg",
                title: "ReactJS",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/angular.svg",
                title: "AngularJS",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/vue.svg",
                title: "VUE",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/php.svg",
                title: "PHP & Frameworks",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/node.svg",
                title: "NodeJS",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dotnet.svg",
                title: "C#.NET",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/python.svg",
                title: "Python",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mysql.svg",
                title: "MySQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/sql.svg",
                title: "Microsoft SQL",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/mongoDB.svg",
                title: "MongoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/it-consultancy/dynamoDB.svg",
                title: "DynamoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-ec2.svg",
                title: "AWS<br />Elastic Compute",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-lambda.svg",
                title: "AWS<br />Lambda",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-rds.svg",
                title: "AWS<br />RDS",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-s3.svg",
                title: "AWS<br />S3",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-route53.svg",
                title: "AWS<br />Route 53",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-elastic.svg",
                title: "AWS<br />Elastic Compute",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-dynmoDb.svg",
                title: "AWS<br />DynamoDB",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-cloud-front.svg",
                title: "AWS<br />Cloud Front",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-cloud-formation.svg",
                title: "AWS<br />Cloud Formation",
                content: "",
            },
            {
                imgSrc: "https://jasthinkassets.s3.amazonaws.com/services-assets/aws-cloud-compute/aws-api-gateway.svg",
                title: "AWS<br />Cloud Front",
                content: "",
            }
        ],
        formSection:
        {
            title: "your Web Application Development",
            content: "Whether you need a brand-new website, a website redesign, or ongoing maintenance, our team of experienced web developers is here to help. We'll partner with you every step of the way, from initial concept and planning to development, launch, and beyond.  We're passionate about creating high-quality websites that deliver exceptional results for your business.",
            formTitle: "Get a Free Web Development Consultation!",
            formSubTitle: "Tell us about your project and receive a custom quote"
        },
        serviceChunks:{
            title: "Custom Web Development Solutions",
            subTitle: "ELEVATE YOUR DIGITAL PRESENCE",
            desc:"From boosting online sales to enhancing educational experiences, we design customized websites to achieve your business objectives. Our expertise spans across business websites, social networking platforms, entertainment sites, nonprofit webpages, informational portals, and dynamic web applications. Rely on us to ensure that your online presence perfectly reflects your goals.",
            chunks: [
                { title: "E-commerce", desc: "Our solutions are designed to enable seamless online transactions, featuring intuitive product catalogs, secure payment gateways, and comprehensive order tracking systems. We strive to enhance user experience and boost sales by integrating personalized recommendations and customer reviews, ensuring a seamless shopping journey."},
                { title: "Portfolio", desc: "Showcase your work with elegance and professionalism through our portfolio websites. Ideal for creative professionals and agencies, these sites highlight your projects, skills, and achievements with visually appealing galleries and detailed project descriptions. Customizable and responsive, our portfolio websites ensure your work shines across all devices."},
                { title: "CMS", desc: "Empower your team with our CMS websites, offering user-friendly interfaces for content management and publication. With customizable templates, SEO tools, and multi-user support, our CMS solutions streamline content creation and enhance your online visibility. Perfect for blogs, news portals, and corporate websites, our CMS platforms are scalable to meet your growing needs."},
                { title: "Educational", desc: "Transform the learning experience with our comprehensive e-learning platforms, including course catalogs, video lectures, quizzes, and progress tracking. Whether for academic institutions or professional training providers, our solutions facilitate effective and engaging online education."},
                { title: "Professional", desc: "Present your company’s story, services, and values through a professionally designed business website. We create informative and aesthetically pleasing sites that include company history, service descriptions, client testimonials, and contact information. Our business websites are optimized to build trust and attract potential clients."},
                { title: "Social Networking", desc: "Foster connections and community with our social networking sites. Designed for maximum user engagement, these platforms feature user profiles, messaging systems, news feeds, and media sharing capabilities. Whether for professional networking or social interaction, our solutions are built to scale and handle dynamic user interactions."},
                { title: "Entertainment", desc: "Keep your audience entertained with our dynamic entertainment websites, integrating media libraries, user ratings, and social sharing features to create engaging and interactive experiences. From streaming services to gaming platforms, our entertainment sites are designed for high performance and seamless content delivery."},
                { title: "Nonprofit", desc: "Support your mission with a dedicated nonprofit website, highlighting your organization’s impact, facilitating donations, and engaging volunteers. With event calendars, impact stories, and donation forms, our nonprofit websites are designed to drive community involvement and support."},
                { title: "Informational", desc: "Provide valuable information to your audience with our well-organized and user-friendly informational websites, ideal for news portals, health information sites, and more. These platforms feature well-organized articles, search functionality, and resource links, ensuring easy access to essential information."},
                { title: "Landing Pages", desc: "Maximize your campaign’s impact with our targeted landing pages, perfect for product launches, event registrations, and marketing campaigns. Designed with a clear call-to-action, our landing pages feature engaging visuals and concise information, optimized for conversions."},
                { title: "Personal Blogs", desc: "Share your insights and experiences with a personal blog, easy to update, with customizable themes and integrated social media sharing. Whether for lifestyle, travel, or hobbies, our personal blogs provide a platform to connect with your audience."},
                { title: "Community Forums", desc: "Create vibrant online communities with our forum solutions, featuring discussion boards, user profiles, and moderation tools to facilitate meaningful interactions and information exchange. Ideal for niche interests and support communities, our forums are built for engagement and growth."},
                { title: "Job Portals", desc: "Connect job seekers with employers through our user-friendly and efficient job portals, featuring job listings, resume submissions, and application tracking to simplify the recruitment process."},
                { title: "Web Applications", desc: "Enhance your business operations with our custom web applications, tailored to streamline your business processes and improve efficiency, from project management tools to CRM systems."},
            ]
        }
    };

    // switch (slug) {
    //     case '':

    //     break;
    //     case 'web-development':
    //     content = <WebDevelopmentContent />;
    //     break;
    //     // Add more cases for other content
    //     default:
    //     content = <DefaultContent />;
    // }

    let circleColor = { backgroundColor: (slug != '' && slug != undefined) ? content[slug].color + "10" : "" };
    let titleColor = { color: (slug != '' && slug != undefined) ? content[slug].color : "" };

    return (
        <>
            {metaData && (
                <Helmet>
                    <title>{metaData.metaTitle}</title>
                    <link rel="icon" href="/favicon.png" />
                    <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                    <meta name="viewport" content="width=device-width,initial-scale=1" />
                    <meta name="theme-color" content="#000000" />
                    <meta name="robots" content="index, follow" />
                    <meta name="description" content={metaData.metaDesc} />
                    <meta name="keywords" content={metaData.metakeywords}></meta>
                </Helmet>
            )}

            {(slug != '' && slug != undefined) ?
                (<>
                    <section class={"main-section serviceSection"}>
                        <Row>
                            <Col md={8}>
                                <div className={"circle-content show-on-mobile"}>
                                    <h4>{content[slug].subTitle}</h4>
                                    <h1 className={"strong"} style={titleColor}>{content[slug].title}</h1>
                                    <p>{(slug != '' && slug != undefined) ? content[slug].desc : ""}</p>
                                    <ul className={"footNotes"}>
                                        <li>{content[slug].footNote[0]}</li>
                                        <li>{content[slug].footNote[1]}</li>
                                        <li>{content[slug].footNote[2]}</li>
                                        <li>{content[slug].footNote[3]}</li>
                                    </ul>
                                </div>
                                <div className={"serviceCircles"} style={circleColor}>
                                    <div className={"circle"} style={circleColor}>
                                        <div className={"circle"} style={circleColor}>
                                            <div className={"circle circle-content-container"} style={circleColor}>
                                                <div className={"circle-content show-on-desktop"}>
                                                    <h4>{content[slug].subTitle}</h4>
                                                    <h1 className={"strong"} style={titleColor}>{content[slug].title}</h1>
                                                    <p>{(slug != '' && slug != undefined) ? content[slug].desc : ""}</p>
                                                    <ul className={"footNotes"}>
                                                        <li>{content[slug].footNote[0]}</li>
                                                        <li>{content[slug].footNote[1]}</li>
                                                        <li>{content[slug].footNote[2]}</li>
                                                        <li>{content[slug].footNote[3]}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <ServiceLinks activeLink={slug} />
                            </Col>
                        </Row>
                    </section>
                    <FillerBox backgroundColor={content[slug].color} textColor="#fff" />
                    <section className={"service-description-section"}>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="8" className={"text-center"}>
                                    <article>
                                        <h4 className={"sub-head mt-4"}>{content[slug].prospectSection.subTitle}</h4>
                                        <h2 className={"main-head"}>{content[slug].prospectSection.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: content[slug].prospectSection.desc }}></div>
                                    </article>
                                </Col>
                                <Col md="12" className={"mt-5"}></Col>
                                <Col lg="4" md="6">
                                    <h3 className={"mt-3"}>{content[slug].whyChooseSection.service}</h3>
                                </Col>
                                <Col lg="8" md="6">
                                    <ul className={"list-group list-group-flush why-choose-aws animated-li"}>
                                        {content[slug].whyChooseSection.points.map((point, index) => (
                                            <li key={index} className="list-group-item overflow-wrap">
                                                <img className={"float-start me-4 service-icon-image"} src={point.iconImg} />
                                                <p dangerouslySetInnerHTML={{ __html: point.content }}></p>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <section className={"main-section"}>
                                <Row>
                                    <Col lg="4" md={"6"}>
                                        <h3 className={"mt-3"}>{content[slug].benefitsSection.service}</h3>
                                    </Col>
                                    <Col lg="8" md={"6"}>
                                        <ul className={"list-group list-group-flush benefits-service animated-li"}>
                                            {content[slug].benefitsSection.points.map((point, index) => (
                                                <li className="list-group-item overflow-wrap">
                                                    <img className={"float-start me-4 number-icon-image"} src={point.iconImg} />
                                                    <p dangerouslySetInnerHTML={{ __html: point.content }}></p>
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section> 
                    {(content[slug].serviceTechSection.title != "") ? (
                        <section className={"main-section technology-section"}>
                            <Container className={"text-center"}>
                                <h4 className={"sub-head"} dangerouslySetInnerHTML={{ __html: content[slug].serviceTechSection.subTitle }}></h4>
                                <h2 className={"main-head"} dangerouslySetInnerHTML={{ __html: content[slug].serviceTechSection.title }}></h2>
                                <div className={"text-center"}>
                                    {(slug != '' && slug != undefined) ?
                                        (
                                            <Row>
                                                {content[slug].techStack.map((techStack, index) => (
                                                    <Col md={3} className={"col-6"} key={index}>
                                                        <div className={"tech-stack"}>
                                                            <img src={techStack.imgSrc} /><br />
                                                            <span className={"strong"} dangerouslySetInnerHTML={{ __html: techStack.title }}></span><br />
                                                            <span dangerouslySetInnerHTML={{ __html: techStack.content }}></span>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        ) : ""}
                                </div>
                            </Container>
                        </section>
                    ) : ""}
                    {(content?.[slug]?.serviceChunks) ? (
                    <section className={"main-section sub-service "}>
                        <Container>
                            <Row>                                
                                <Col md={6}>
                                    <h4 className="sub-head mt-4">{ content[slug].serviceChunks.subTitle }</h4>
                                    <h2 className="main-head">{ content[slug].serviceChunks.title }</h2>
                                    <p>{ content[slug].serviceChunks.desc }</p>
                                    <ul className={"icon-list"}> 
                                        {content[slug].serviceChunks.chunks.map((chunk, index) => (
                                            <li>
                                                { chunk.title }
                                                <div className={"sub-service-detail"}>
                                                    <strong>{ chunk.title }: </strong><br />
                                                    { chunk.desc }
                                                </div>
                                            </li>                                             
                                        ))}
                                    </ul>                                    
                                </Col>
                                <Col md={6} className={"sub-service-image"}>
                                    <img src={"https://jasthinkassets.s3.amazonaws.com/web-dev.jpg"} />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    ) : ""}
                    <section className={"form-section top-shadow"}>
                        <Container>
                            <Row className={"justify-content-center text-center"}>
                                <Col sm={8}>
                                    <h2 className={"mb-2"}><strong>Jasthink Softwares &mdash; Your Trusted Partner in {content[slug].formSection.title}!</strong></h2>
                                    <p className={"mb-5"} dangerouslySetInnerHTML={{ __html: content[slug].formSection.content }}></p>
                                </Col>
                            </Row>
                            <Row className={"justify-content-center mt-5"}>
                                <Col sm={12} md={8} lg={6} className={"mb-5"}>
                                    <ContactForm ctaTxt={content[slug].formSection.formTitle} subHeadTxt={content[slug].formSection.formSubTitle} paraTxt="" />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    
                    {(slug == "ux-cx-design") ? (
                        <ProjectProcess />
                    ) : ""}
                </>
                ) : ""}
        </>
    );
}


const CloudComputingContent = () => {
    return (
        <div>

        </div>
    );
};

const WebDevelopmentContent = () => {
    return <div>Web Development Content</div>;
};

const DefaultContent = () => {
    return <div>Default Content (or a 404 page)</div>;
};


export default SingleService;