import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
// import './thin-icons.css';

import App from "./App";

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
    <>
      <App />
    </>
);

reportWebVitals();
