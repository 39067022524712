// AnimatedCircles.js
import React from 'react';
import './assets/styles/AnimatedCircles.css'; // Import CSS file for styling


function AnimatedCircles() {
    return (
        <div className="animated-circles-container">
            <div className="circle circle1"></div>
            <div className="circle circle2"></div>
            <div className="circle circle3"></div>
        </div>
    );
}

export default AnimatedCircles;
