// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProcessSteps from './processSteps';

// images 

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function HowWeDoIt() {

    let tileData = [
        {
            number:"01",
            heading:"Define",
            paragraph:"Analyze the data and define a range of viable solutions"
        },
        {
            number:"02",
            heading:"Design",
            paragraph:"Brainstorm on the ideas and design the best possible user experience"
        },
        {
            number:"03",
            heading:"Develop",
            paragraph:"Code, validate, verify and prepare the solution to  deploy live"
        },
        {
            number:"04",
            heading:"Deploy",
            paragraph:"Hu ya! We made the next level of UX available to the customers"
        }
    ];
        
    return (
        <>
            <section className={"main-section how-we-do-section "}>
                <Container>
                    <Row className={"justify-content-between"}>                        
                        <Col md={10} lg={6} className={"mx-auto text-center"}>
                            <h5 className={"sub-head"}>THE SECRET SAUCE</h5>
                            <h2 className={"main-head"}>Agile Progress: Timely, Productive Delivery</h2>
                        </Col>
                        <Col lg={12}></Col>
                        <Col lg={1}></Col>
                        <Col lg={5} md={7} className={"justify-content-end how-we-do-banner"}>
                             <ProcessSteps tileData={tileData} ></ProcessSteps>                             
                        </Col>                        
                        <Col className={"process-description"} lg={5} md={5}>
                            <p className={"mt-5 pt-5"}>In today's digital environment, we optimize various touchpoints, streamline processes, and utilize data metrics for success. By adapting to customer expectations and focusing on core values, businesses can thrive in the digital age while remaining committed to customers' needs and preferences.</p>
                            <p className={"voilet"}>We confer, plan & act to enhance your business for tomorrow, today.</p>
                            <p>With clear vision, innovation, and strategic planning, we make mindful decisions to achieve milestones for your product or business success. By minimizing development challenges, we aim to boost revenue and drive growth.</p>
                            {/* <p><a href="/case-studies" className={"btn btn-primary outlined-btn"}>Read the Success Stories</a></p> */}
                            <div className={"hash-tags"}>
                                #GrowthStrategy #ProductSuccess #VisionaryLeadership 
                            </div>
                        </Col>  
                        <Col lg={1}></Col>                                              
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HowWeDoIt;