// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Typewriter from '../../../../components/typewrite';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images 

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function HomeBanner() {
        
    return (
        <>
            <section className={"main-section homeBanner-section"}>
                <Container>
                    <Row className={"justify-content-between"}>                        
                        <Col lg={6} md={8}>
                            <h5 className={"sub-head"}>DNA TO SUCCESS</h5>
                            <h2 className={"main-head"}><Typewriter toRotate='["Nurture", "Foster", "Craft", "Create", "Deliver"]' period="2000" textColor="#741E7E" /> <br />Customer-centeric<br />  Digital
                            Experiences</h2>
                            <p>In today's digital landscape, putting customers first is vital. Our strategy revolves around tailoring every aspect of your audience's journey, ensuring they receive the best possible experience.</p>
                            {/* <p className={"voilet"}>It always been our pleasure to be a part of brands, nurturing & growing together.</p>*/}
                            <a href="/get-started" className={cx('btn btn-primary mt-2 mb-3')}>Get Started</a><br />
                            <div className={"hash-tags mt-3"}>
                                #CustomerCentric #DigitalExperience #UserExperience
                            </div>
                        </Col>
                        <Col lg={6} md={4} className={"justify-content-end"}>
                             
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HomeBanner;