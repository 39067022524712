import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import styles from './assets/customerStyles.css';
import cx from 'classnames';
function CustomerTile(props) {
    const { logoSrc, logoAlt, heading, subHeading, paragraph, link } = props;

    return(
        <Card className={"customer-tile"}>            
            <Card.Body>
                <img alt={logoAlt} className={"customer-image"} src={logoSrc} />
                <Card.Title>{heading}</Card.Title>
                <span className={"sub-title"}>{subHeading}</span>
                <Card.Text>
                    {paragraph}
                </Card.Text>
            </Card.Body> 
        </Card>
    );
}

export default CustomerTile;