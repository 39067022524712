import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomerTile from './customerTile';

// importing images
import styles from './assets/styles.css';

function TrustedCustomers(props) {
    let { subTitle, sectionTitle } = props;

    subTitle = (subTitle == undefined || subTitle == "") ? "for our best IT services from industries all over the globe":subTitle;
    sectionTitle = (sectionTitle == undefined || sectionTitle == "") ? "Trusted Customers":sectionTitle;

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        adaptiveHeight: true,
        arrows: false,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }]
    };
 
    let customers = [
        {
            logoSrc: "https://jasthinkassets.s3.amazonaws.com/qualsight.png",
            logoAlt: "Qualsight",
            heading: "VIRTUAL TOURISM",
            subHeading: "3 yrs of partnership",
            paragraph: "Formly knowns as Georama - A portal and a marketplace for guides and tourists to join together on an online tour/virtual tour of a city or event.",
            link: "#",
        },
        {
            logoSrc: "https://jasthinkassets.s3.amazonaws.com/hashching.png",
            logoAlt: "Hashching",
            heading: "MORTGAGE & FINANCE",
            subHeading: "9+ yrs  of partnership",
            paragraph: "The best online and first of it’s kind in Austrial FinTech market. A platform for borrowers to avail loans mortgage professionals to serve the loans using CRM.",
            link: "#",
        },
        {
            logoSrc: "https://jasthinkassets.s3.amazonaws.com/advisr.png",
            logoAlt: "Advisr",
            heading: "INSURANCES",
            subHeading: "1.5 yr  of partnership",
            paragraph: "An online marketplace for Insurance providers in Austrlia. Enabling the customers and the brokers to join together and serve best possible insurance.",
            link: "#",
        },
        {
            logoSrc: "https://jasthinkassets.s3.amazonaws.com/headroom.png",
            logoAlt: "Headroom Learning",
            heading: "EDUCATION & LEARNING",
            subHeading: "4 yrs of partnership",
            paragraph: "A Technology for life! Headroom learning has participated in creating e-learning  & training applications for students from grade 3.",
            link: "#",
        },
        {
            logoSrc: "https://jasthinkassets.s3.amazonaws.com/greenvits.png",
            logoAlt: "Greenvits",
            heading: "HEALTH & WELLNESS",
            subHeading: "2 yrs of partnership",
            paragraph: "A E-Commerce website and blog for a UK based company providing nartural health & immunity products.",
            link: "#",
        },
        {
            logoSrc: "https://jasthinkassets.s3.amazonaws.com/junglee-games.png",
            logoAlt: "Junglee Games",
            heading: "GAMING DESIGN",
            subHeading: "2 yrs of partnership",
            paragraph: "Junglee is revolutionizing how people play games. The notable games include Howzat, Junglee Rummy, Eatme.io, Poker, Solitaire Gold, and Carrom Stars.",
            link: "#",
        },
    ];

    return (                
        <section className={"trusted-customers"}>
            <Container>
                <Row>  
                    <Col md={12}>
                        <h2 className={'main-head'}>{sectionTitle}</h2>
                        <p>{subTitle}</p>
                    </Col>                  
                    <Col className={"text-center"}>                        
                        <Slider {...settings}>
                            {customers.map((customer, index) => (
                                <CustomerTile key={index} logoAlt="customer.logoAlt" logoSrc={customer.logoSrc} heading={customer.heading} subHeading={customer.subHeading} paragraph={customer.paragraph} link={customer.link}></CustomerTile>                           
                            ))}
                        </Slider>                            
                    </Col>                    
                </Row>
                <Row>
                    <Col>
                    <p className={"text-center mt-5"}>
                    <strong>and many others…</strong>
                    </p>
                    </Col>
                </Row>
            </Container>
        </section>        
    )
}

export default TrustedCustomers;
