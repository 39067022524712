// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Parser from 'rss-parser';
import axios from 'axios';


import Collabs from '../../components/collabs';
import { Helmet } from "react-helmet";

import TextQuote from '../../components/textQuote';
import TrustedCustomers from "../../components/trustedCustomers"
import ProjectProcess from "../../components/projectProcess"
import AllServices from '../../components/ourServices';

// images  

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function TheITScoop() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
        
          const url = 'https://theitscoop.wordpress.com/feed/'; // Replace with your actual URL
          const response = await axios.get(url);
          alert('hello');
          const data = response.data; // Get the RSS feed content
           
          //const parser = new Parser();
          //const feed = await parser.parseString(data); // Parse the text content
          //setPosts(feed.items); // Update state with parsed blog posts
        };
        fetchPosts();
      }, []);
      
    return (
        <>
            <Helmet>
                <title>Expert IT Services for Your Business | Jasthink Softwares</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Explore our comprehensive range of IT services including web development, mobile app development, DevOps solutions, quality assurance, project management, and more. Partner with Jasthink Softwares for innovative technology solutions tailored to your needs." />
                <meta name="keywords" content="IT services, web development, mobile app development, DevOps solutions, quality assurance, project management, software development, technology solutions, digital transformation, IT consulting"></meta>
            </Helmet>                    
            <section className={"main-section-textquote pt-5"}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <TextQuote textColor="#741E7E" data="Let’s Discover how our team’s expertise and unwavering commitment to ethics can benefit your business."></TextQuote>
                        </Col>                         
                    </Row>
                </Container>
            </section>
            <section className={"main-section"}>
                <Container>
                <h2>The IT Scoop</h2>
                    {posts.length > 0 ? (
                        posts.map((post) => (
                        <div className="blog-post" key={post.title}>
                            <h3>{post.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: post['content:encoded'] || post.description }}></p>
                            <p className="post-date">Published: {new Date(post.pubDate).toLocaleDateString()}</p>
                            {/* Add a link to the original post if desired */}
                            {/* <a href={post.link} target="_blank" rel="noopener noreferrer">Read More</a> */}
                        </div>
                        ))
                    ) : (
                        <p>Loading posts...</p>
                    )}
                </Container>
            </section>               
            <TrustedCustomers subTitle={"with industries from all over the globe"} sectionTitle={"Our Exciting Collaborations"} />  
            <ProjectProcess />
        </>
    );
}

export default TheITScoop;