// Components and plugins
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
// images 

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

import HomeBanner from './components/homeBanner';
import BuildForAll from './components/buildForAll';
import HowWeDoIt from './components/howWeDo';
import TrustedCustomers from '../../components/trustedCustomers';
import OurServices from './components/ourServices';
import Collabs from '../../components/collabs';
import Industries from '../../components/industries';
import ProjectProcess from "../../components/projectProcess";
import AllServices from '../../components/ourServices';

function HomePage() {
    const [count, setCount] = useState(0);
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        // document.title = `You clicked ${count} times`;
    });

    let heroData = [
        {
            title: "Unlock Potential <br />with Innovative IT Solutions",
            subTitle: "EMPOWERING BUSINESSES",
            text: "At Jasthink, we're dedicated to empower your business with innovative IT solutions. From user-centric design to data-driven insights, our expertise transforms challenges into opportunities. Partner with us to stay ahead in the digital age.",
            footNote: "#growth #innovation #empowerment",
            CTAtxt: "Learn More",
            CTAlnk: "/services",
            bgColor:"#FAFAFA",
            bgImage: "",
            btnStyle:"outlined"
        },
        {
            title: "Enhancing Experiences <br />through Intuitive UX Design",
            subTitle: "IT'S ALL ABOUT EXPERIENCES!",
            text: "Our user-centric design prioritizes seamless experiences for the customers. We focus on usability, accessibility, and aesthetics to create efficient solutions. See how our designs elevate customer satisfaction and workforce productivity.",
            footNote: "#technology #forpeople #reliablesystems",
            CTAtxt: "Learn More",
            CTAlnk: "/services",
            bgColor:"#EAEAEA",
            bgImage: "",
            btnStyle:"outlined"
        },
        {
            title: "Drive Success with <br />Data-Driven Solutions",
            subTitle: "FOR PROSPEROUS BUSINESS VENTURES",
            text: "In today's era, data is the most valuable asset. Our solutions unlocks its potential to empower the businesses with data analysis, visualization, and predictive modeling, to keep you competitive and agile in a data-centric world.",
            footNote: "#dataanalytics #robostsystems #clarity",
            CTAtxt: "Learn More",
            CTAlnk: "/services",
            bgColor:"#FFEEEE",
            btnStyle:"outlined"
        },
        {
            title: "Partnering for <br />Startup Success",
            subTitle: "VISIONS TO REALITY",
            text: "As your startup tech partner, we bring innovative ideas to life. Our team specializes in scalable, efficient tech solutions. With strategic guidance and technical expertise, we set you on the path to success.",
            footNote: "#startup #ready #success",
            CTAtxt: "Learn More",
            CTAlnk: "/services",
            bgColor:"#EEEEFF",
            bgImage: "",
            btnStyle:"outlined"
        },
        {
            title: "Embracing <br />Future Technologies",
            subTitle: "STAY AHEAD WITH INNOVATIONS",
            text: "Embrace the future, today. We commit to the latest technology, keeping your business at the forefront of innovation. From AI to cloud computing, we leverage advancements to drive your digital transformation.",
            footNote: "#futureready #ai #innovations",
            CTAtxt: "Learn More",
            CTAlnk: "/services",
            bgColor:"#FFEEFF",
            bgImage: "",
            btnStyle:"outlined"
        }
    ];    
    
    return (
        <>
            <Helmet>
                <title>Jasthink Softwares &mdash; Driving Business Value with Future-Ready Solutions</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Revolutionizing the way businesses operate with cutting-edge software solutions." />        
                <meta name="keywords" content="custom software solutions, enterprise software development, cloud software solutions, mobile application development, web application development, IT consulting solutions, business process automation software, AI and machine learning solutions"></meta>
            </Helmet>
            <HomeBanner />
            <HowWeDoIt />
            <AllServices />
            <OurServices />
            <TrustedCustomers />
            <BuildForAll />                                                
            <Industries />
            <Collabs />
            <ProjectProcess />            
        </>
    );
}

export default HomePage;