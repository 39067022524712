import React, { createContext, useContext, useState } from 'react';

import ReactDOM from 'react-dom';
import { Route, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';

import Header from './Layouts/header'
import Footer from './Layouts/footer'

import Root from './pages/homePage/';
import About from './pages/aboutPage/';
import Services from './pages/servicesPage/';
import Service from './pages/singleService/';
import Products from './pages/productsPage/';
import CaseStudies from './pages/caseStudyPage/';
import Contact from './pages/contactPage/';
import GetStarted from './pages/getStarted';
import PrivacyPolicy from './pages/privacyPolicy';
import TheITScoop from './pages/theItScoop';

import { ClassProvider } from './context/classProvider';

const router = createBrowserRouter([
  //JSX need to be done in other way than using RouterProvider
  // createRoutesFromElements(
  //   <Route path="/" element={<Root />}>
  //     <Route path="about-us" element={<About />} />
  //     <Route path="services" element={<Services />} />
  //     <Route path="services/:serviceId" element={<Service />} />
  //     <Route path="products" element={<Products />} />
  //     <Route path="case-studies" element={<CaseStudies />} />
  //     <Route path="case-studies/:caseStudyId" element={<CaseStudies />} />
  //     <Route path="contact" element={<Contact />} />
  //   </Route>
  // ) 
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/about-us",
    element: <About />
  },
  {
    path: "/expertise",
    element: <Services />
  },
  {
    path: "/services/:serviceId",
    element: <Service />
  },
  {
    path: "/products",
    element: <Products />
  },
  {
    path: "/case-studies",
    element: <CaseStudies />
  },
  {
    path: "/case-studies/:caseStudyId",
    element: <CaseStudies />
  },
  {
    path: "/contact-us",
    element: <Contact />
  },
  {
    path: "/get-started",
    element: <GetStarted />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  },
  {
    path: "/the-it-scoop",
    element: <TheITScoop />
  }
]);

const tagManagerArgs = {
  gtmId: 'G-X4Y7YRSDHH',
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className={"App"}>
      <Helmet>
        <title>Jasthink Softwares &mdash; Driving Business Value with Future-Ready Solutions</title>
        <link rel="icon" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Revolutionizing the way businesses operate with cutting-edge software solutions." />        
        <meta name="keywords" content="custom software solutions, enterprise software development, cloud software solutions, mobile application development, web application development, IT consulting solutions, business process automation software, AI and machine learning solutions"></meta>
      </Helmet>
      <ClassProvider>
        <Header />
        <RouterProvider router={router} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Jasthink Softwares Private Limited",
              "image": "/jasthink.svg",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Plot No. 57, Ground Floor, Industrial Area, Phase 1",
                "addressLocality": "Chandigarh",
                "addressRegion": "UT",
                "postalCode": "160002",
                "addressCountry": "India"
              },
              "telephone": "+916284286700",
              "priceRange": "$$-$$$",
              "currenciesAccepted": "USD",
              "paymentAccepted": "Cash, Credit Card",
              "openingHours": "Mo-Sa 09:00-18:00",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 30.7109335,
                "longitude": 76.8050407
              },
              "sameAs": [
                "https://www.facebook.com/jasthinksoftwares",
                "https://in.linkedin.com/company/jasthink-softwares"
              ]
            }
          `}
        </script>
        <Footer />
      </ClassProvider>
    </div>
  );
}

export default App;
