// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images 

// Styles and Css
import styles from './assets/styles.css';
import cx from 'classnames';

function serviceLinks(props) {
    const { activeLink } = props;
    
    useEffect(() => {
        
    });

    return (
        <>
            <ul className={"service-links"}>
                <li><a href="/services/cloud-computing" className={ (activeLink == "cloud-computing") ? 'active' : '' }>AWS Cloud Computing</a></li>
                <li><a href="/services/it-consultancy" className={ (activeLink == "it-consultancy") ? 'active' : '' }>IT Consultancy</a></li>
                <li><a href="/services/enterprise-solution" className={ (activeLink == "enterprise-solution") ? 'active' : '' }>Enterprise Solutions</a></li>
                <li><a href="/services/ux-cx-design" className={ (activeLink == "ux-cx-design") ? 'active' : '' }>UX/CX Design</a></li>
                <li><a href="/services/mobile-app-development" className={ (activeLink == "mobile-app-development") ? 'active' : '' }>Mobile App Development</a></li>
                <li><a href="/services/web-app-development" className={ (activeLink == "web-app-development") ? 'active' : '' }>Web App Development</a></li>
                {/* <li><a href="/services/web-app-development" className={ (activeLink == "web-app-development") ? 'active' : '' }>Dedicated Team</a></li>
                <li><a href="/services/web-app-development" className={ (activeLink == "web-app-development") ? 'active' : '' }>Dedicated Team</a></li> */}
                {/* <li><a href="/services/web-app-development" className={ (activeLink == "web-app-development") ? 'active' : '' }>MVP Development</a></li>
                
                <li><a href="/services/web-app-development" className={ (activeLink == "web-app-development") ? 'active' : '' }>Product Development</a></li> */}
            </ul>            
        </>
    );
}

export default serviceLinks;