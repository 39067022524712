// Components and plugins
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import { Helmet } from "react-helmet";

//Components
import ContactProcess from './components/contactProcess'
import DynamicFillerBox from '../../components/dfillerBox';
import ContactForm from '../../components/contactForm';
import CircleImage from '../../components/circleImage';

// images 

// Styles and Css
import Styles from './assets/styles.css';
import officeRoom from './assets/images/hero-banner-office.jpg';
import meetingRoom from './assets/images/hero-banner-meeting.jpg'
import cx from 'classnames';

function Contact() {    
    useEffect(() => {
        // Update the document title using the browser API
        
    });

    const circleData = [
        {
            circleColor: "#DEC3EA55",
            contentPlacement: "right",
            authorName:"Kawaljit Singh",
            authorImg: "https://jasthinkassets.s3.amazonaws.com/team-member.jpg",
            designation:"CEO",
            authorMessage:"<strong>We are good in it, driving a positive change</strong> for the benefit of the society. Together, we combine innovation and collective knowledge to create services or apps for all.",
            sectionContent:"<h3><strong>End-to-end project management</strong>, analysis, data visualisation, cloud computing, creatives, UX/CX and digital marketing services.</h3>",
            footNote:""
        },
    ]; 
 
    return (
        <>
            <Helmet>
                <title>Get in Touch with Jasthink Softwares | Contact Us Today</title>
                <link rel="icon" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="robots" content="index, follow" />
                <meta name="description" content="Reach out to the Jasthink Softwares team to discuss your project, inquire about our services, or get assistance with technical support. We're here to help you succeed!" />        
                <meta name="keywords" content="Contact us, get in touch, Jasthink Softwares contact, business inquiries, technical support, customer service, contact information, reach out, get assistance"></meta>
            </Helmet>        
            <div className={"hide-overflow"}>
                <section className={cx("main-section", "hero-section")}>
                    <Container>
                        <Row>                        
                            <Col md={6} className={"image-section"}>
                                <img src={officeRoom} className="contact-space" alt="JasThink Softwares" />
                                <img src={meetingRoom} className="contact-room" alt="Team discusions" />  
                            </Col>
                            <Col md={6}>
                                <article>
                                    <h1>Your IT Solutions begin with a conversation &mdash; Reach Out to Us!</h1>
                                    <p>Our team of IT experts is here to listen, understand, and transform your technology challenges into opportunities. Contact us now and let's begin your path to seamless IT excellence.</p>
                                    <hr className="spacer" />
                                    <p className={"voilet"}>Turning IT challenges into strategic opportunities, our expertise, your value.</p>
                                    <p className={"typographic"}><span>Create,</span> <span>Launch,</span> <span>Succeed,</span> <span>Repeat.</span></p>
                                </article>
                            </Col>
                        </Row>
                    </Container>
                </section>    
                <section className="contact-form">
                    <Container>
                        <Row>
                            <Col md={6} className={"show-on-desktop"}>          
                                {/* <CircleImage data={circleData[0]} />                         */}
                            </Col>
                            <Col md={6}>
                                <ContactForm ctaTxt="Connect with us" subHeadTxt="Let's shape the future together." paraTxt="Start the conversation to explore tailored IT solutions and experience seamless, expert support from our dedicated team." buttonTxt="" submitLnk="" />
                            </Col>                                                
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <address className="contact-address">                                                                                            
                                    <Row>
                                        <Col md={4}>
                                            <div className="addresses-info">                                            
                                                <ul>
                                                    <li>                                              
                                                        <p>
                                                            <strong>Jasthink Softwares Pvt. Ltd.</strong><br />
                                                            <span>                                                                                       
                                                                Plot #57, Ground Floor, Indl Area-1, <br />
                                                                Chandigarh, UT - 160002
                                                            </span>
                                                        </p>
                                                        <small><strong>Amelia Cooper</strong>, Relationship Manager</small>
                                                    </li> 
                                                    {/* <li>
                                                        <strong>Vancouver (CA)</strong><br />
                                                        Plot #57, Industrial Area, Phase 1, <br />
                                                        Chandigarh, UT - 160002
                                                    </li>
                                                    <li>
                                                        <strong>England (EU)</strong><br />
                                                        Plot #57, Industrial Area, Phase 1, <br />
                                                        Chandigarh, UT - 160002
                                                    </li> */}
                                                </ul>
                                            </div> 
                                        </Col>
                                        <Col md={4}>                        
                                            <div className="addresses-info">
                                                <ul>
                                                    <li>
                                                        <strong>Join us on Whatsapp</strong><br />
                                                        <a href="tel:+916284286700">+91 628 428 6700</a>
                                                    </li>
                                                    <li>
                                                        <strong>General Enquiry:</strong><br />
                                                        <a href="mailto:hello@jasthink.com">hello@jasthink.com</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="addresses-info">
                                                <ul>                                    
                                                    <li>
                                                        <strong>Sales:</strong><br />
                                                        <a href="mailto:Sales@jasthink.com">Sales@jasthink.com</a>
                                                    </li>
                                                    <li>
                                                        <strong>Tech Support:</strong><br />
                                                        <a href="mailto:tech-support@jasthink.com">tech-support@jasthink.com</a>
                                                    </li>                                     
                                                </ul>
                                            </div>
                                        </Col>                                    
                                    </Row>
                                </address>                        
                            </Col>
                        </Row>
                    </Container>
                </section>
                <div className="mt-5">&nbsp;</div> 
                <DynamicFillerBox backgroundColor="#741E7E" textColor="#fff" punchline="Empowering your business with tailored IT solutions for a brighter, more efficient, and secure digital future." quote="Innovate, Transform, Succeed - It's all about the right solutions." />
                <ContactProcess />
            </div>
        </>
    );
}

export default Contact;