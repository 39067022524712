// Components and plugins
import React, { useState, useEffect } from 'react';
import Styles from "./assets/styles.css";

//images

function textQuote(props) {    
    const { data, textColor } =  props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        // Update the document title using the browser API        
    }); 

    let quoteStyle = {
        color: textColor,
    }
 
    return (
        <div className="txt-quote" style={ quoteStyle }>
            <p><span>“</span>{data}</p>                     
        </div>
    );
}
export default textQuote;
