import React, { createContext, useContext, useState } from 'react';

// Create a context to share the function and state
const ClassContext = createContext();

// Create a provider component to provide the function and state
const ClassProvider = ({ children }) => {
  const [isClassAdded, setIsClassAdded] = useState(false);

  const showGetStartedForm = () => {
    setIsClassAdded(true);
  };

  const removeClassFromDiv = () => {
    setIsClassAdded(true);
  };

  return (
    <ClassContext.Provider value={{ isClassAdded, showGetStartedForm }}>
      {children}
    </ClassContext.Provider>
  );
};

export { ClassProvider };